import { Size } from "@customTypes/size";

export const mapSizeToEnum = (size: string): Size => {
  if (size === "SMALL") return Size.Small;
  if (size === "MEDIUM") return Size.Medium;
  if (size === "LARGE") return Size.Large;
  if (size === "EXTRA_LARGE") return Size.ExtraSLarge;
  if (size === "EXTRA_EXTRA_LARGE") return Size.ExtraExtraLarge;
  if (size === "ONE_SIZE_FITS_ALL") return Size.OneSizeFitsAll;
  return Size.Unknown;
};

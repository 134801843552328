import { UserType } from "@customTypes/user_type";
import { WalletContext } from "@providers/wallet_provider";
import { LocalStoreKey, getLocalStoreItem } from "@storage/local_storage";
import { AuthUseCase } from "@use_case/auth_use_case";
import { useContext, useEffect, useState } from "react";
import { useAccount, useDisconnect } from "wagmi";
import { useIsMounted } from "./use_is_mounted";

type LoginProps = {
  walletAddress: string;
  signature: string;
  message: string;
};

const useAuthentication = () => {
  const [userType, setUserType] = useState(UserType.unknown);
  const { isConnected } = useContext(WalletContext);
  const { isMounted } = useIsMounted();

  // Set user type on mount.
  useEffect(() => {
    const storedToken = getLocalStoreItem(LocalStoreKey.accessToken);
    if (isMounted) return;
    if (!!storedToken) {
      if (isConnected && !isMounted) {
        setUserType(UserType.wallet);
        return;
      }
      if (userType === UserType.wallet) {
        guestLogin();
      }
      setUserType(UserType.guest);
    } else {
      guestLogin();
    }
  }, []);

  useAccount({
    onDisconnect: () => {
      guestLogin();
    },
  });

  const { disconnectAsync } = useDisconnect();

  const walletLogin = async ({
    walletAddress,
    signature,
    message,
  }: LoginProps) => {
    const response = await AuthUseCase.walletLogin({
      walletAddress,
      signature,
      message,
    });
    if (response.ok) {
      setUserType(UserType.wallet);
    } else {
      setUserType(UserType.guest);
      disconnectAsync();
    }
  };

  const guestLogin = async () => {
    const response = await AuthUseCase.guestLogin();
    if (response.ok) {
      setUserType(UserType.guest);
    }
  };

  const logoutWalletUser = async () => {
    await disconnectAsync();
  };

  return {
    walletLogin,
    userType,
    logoutWalletUser,
    guestLogin,
  };
};

export { useAuthentication };

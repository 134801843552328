import { Layout } from "@components/layout";
import { MenuProvider } from "@providers/menu_provider";
import { WalletProvider } from "@providers/wallet_provider";
import "../styles/globals.css";
import { useIsMounted } from "@utils/hooks/use_is_mounted";
import type { AppProps } from "next/app";
import { CartProvider } from "@providers/cart_provider";
import { SignatureProvider } from "@providers/signature_provider";
import { ErrorProvider } from "@providers/error_provider";
import { CurrencyProvider } from "@providers/currency_provider";
import { StoreProvider } from "@providers/store_provider";
import { isStandbyMode } from "@utils/constants";

export default function App({ Component, pageProps }: AppProps) {
  const { isMounted } = useIsMounted();
  if (!isMounted) {
    return null;
  }

  if (isStandbyMode) {
    return (
      <MenuProvider>
        <Layout>
          <Component {...pageProps} />
        </Layout>
      </MenuProvider>
    );
  }

  return (
    <ErrorProvider>
      <WalletProvider>
        <SignatureProvider>
          <CurrencyProvider>
            <StoreProvider>
              <CartProvider>
                <MenuProvider>
                  <Layout>
                    <Component {...pageProps} />
                  </Layout>
                </MenuProvider>
              </CartProvider>
            </StoreProvider>
          </CurrencyProvider>
        </SignatureProvider>
      </WalletProvider>
    </ErrorProvider>
  );
}

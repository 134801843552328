export enum Currency {
  usd,
  gbp,
  eth,
}

export const getCurrencySymbol = (currency: Currency) => {
  switch (currency) {
    case Currency.eth:
      return "ETH";
    case Currency.usd:
      return "$";
    case Currency.gbp:
      return "£";
  }
};

export const getCurrencyString = (currency: Currency) => {
  switch (currency) {
    case Currency.usd:
      return "USD";
    case Currency.gbp:
      return "GBP";
    case Currency.eth:
      return "ETH";
  }
};

export const getCurrencyFromString = (
  currencyString: string
): Currency | undefined => {
  switch (currencyString) {
    case "USD":
      return Currency.usd;
    case "GBP":
      return Currency.gbp;
    case "ETH":
      return Currency.eth;
    case "ETH_SEPOLIA":
      return Currency.eth;
    default:
      return undefined;
  }
};

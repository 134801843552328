export enum LocalStoreKey {
  cartItem = "hape-cart-item",
  deliveryUser = "hape-delivery-user",
  firstVisit = "hape-first-visit",
  accessToken = "hape-access-token",
  activeCurrency = "hape-active-currency",
  orderId = "hape-order-id",
  paymentSessionId = "hape-payment-session-id",
}

export const getLocalStoreItem = (key: LocalStoreKey): string | null => {
  return localStorage.getItem(key);
};

export const setLocalStoreItem = (value: string, key: LocalStoreKey) => {
  localStorage.setItem(key, value);
};

export const deletLocalStoreItem = (key: LocalStoreKey) => {
  localStorage.removeItem(key);
};

import styled from "styled-components";
import Image from "next/image";

import HapeLogo from "../../public/hape_logo.svg";
import MenuLogo from "../../public/menu_icon.svg";
import HapeStandbyLogo from "../../public/hape-standby-icon.svg";

import { Clickable, Column, Row } from "../base_styles";
import { Cart } from "./cart";
import { WalletConnect } from "../wallet_connect";
import { useIsMobileWidth } from "@utils/hooks/use_is_mobile_width";
import IconButton from "../icon_button";

import { HeaderClickableItem, HeaderTextItem } from "./header_items";
import {
  ERROR_MESSAGE_HEIGHT_DESKTOP,
  ERROR_MESSAGE_HEIGHT_MOBILE,
  HEADER_HEIGHT_MOBILE_PX,
  isStandbyMode,
} from "@utils/constants";
import { BREAKPOINTS } from "@styles/breakpoints";
import { useContext } from "react";
import { MenuContext } from "@providers/menu_provider";
import { useRouter } from "next/router";
import { Routes } from "@router/routes";
import Link from "next/link";
import { colours } from "@styles/theme";
import { ErrorContext } from "@providers/error_provider";
import CurrencyDropDown from "@components/currency_dropdown";

interface WrapperProps {
  hideShadow: boolean;
  desktopHeight: number;
}
const Wrapper = styled.header<WrapperProps>`
  position: relative;
  display: flex;
  flex-direction: row;
  padding: 0px 32px;
  width: 100%;
  align-items: center;
  justify-content: space-between;
  background: #ffffff;
  box-shadow: ${(props) =>
    `${props.hideShadow ? "none" : "0px 0px 36px rgba(0, 0, 0, 0.15)"}`};
  overflow: visible;
  height: ${(props) => `${props.desktopHeight}px`};
  z-index: 10;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    height: ${HEADER_HEIGHT_MOBILE_PX};
    padding: 12px 24px;
  }
`;

interface CardWrapperProps {
  hideMargin: boolean;
}
const CartWrapper = styled.div<CardWrapperProps>`
  margin-right: ${(props) => `${props.hideMargin ? "0" : "0px"}`};
  display: flex;
  align-items: center;
  flex-direction: row;
`;

const LeftContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-start;
`;

const CenterContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
`;

const RightContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: flex-end;
`;

const RightWrapperContainer = styled(Row)`
  flex: 1;
  justify-content: space-between;
  max-width: 400px;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    justify-content: flex-end;
  }
`;

interface ErrorContainerInterface {
  show: boolean;
}
const ErrorContainer = styled.div<ErrorContainerInterface>`
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: ${colours.red};
  height: ${(props) =>
    props.show ? `${ERROR_MESSAGE_HEIGHT_DESKTOP}px` : "0px"};
  transition: 0.2s ease-in;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    height: ${(props) =>
      props.show ? `${ERROR_MESSAGE_HEIGHT_MOBILE}px` : "0px"};
  }
`;

const ErrorText = styled.h2`
  color: ${colours.white};
  font-size: 14px;
  font-weight: 600;
  text-align: center;
  padding: 12px;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    font-size: 12px;
    text-align: left;
  }
`;

const StandbyContainer = styled(Row)`
  width: 100%;
  justify-content: center;
`;

const CurrencyDropDownWrapper = styled.div`
  width: 91px;
`;

type Props = {
  desktopHeaderHeight: number;
  hideShadow: boolean;
};

const Header = ({ desktopHeaderHeight, hideShadow }: Props) => {
  const { isMobileWidth } = useIsMobileWidth();
  const { openMenu } = useContext(MenuContext);
  const { menuErrorMessage } = useContext(ErrorContext);
  const router = useRouter();

  const isProductPage =
    router.pathname === Routes.products.replace(".html", "");
  const isLookBookPage =
    router.pathname === Routes.lookBook.replace(".html", "");

  const HapeLink = (
    <Link href={Routes.home}>
      <Clickable>
        <Image priority src={HapeLogo.src} height={20} width={120} alt="" />
      </Clickable>
    </Link>
  );

  if (isStandbyMode) {
    return (
      <Wrapper desktopHeight={desktopHeaderHeight} hideShadow={hideShadow}>
        <StandbyContainer>
          <Link href={Routes.home}>
            <Clickable>
              <Image
                priority
                src={HapeStandbyLogo.src}
                height={30}
                width={180}
                alt=""
              />
            </Clickable>
          </Link>
        </StandbyContainer>
      </Wrapper>
    );
  }
  return (
    <Column>
      <Wrapper desktopHeight={desktopHeaderHeight} hideShadow={hideShadow}>
        <LeftContainer>
          {isMobileWidth ? (
            <IconButton
              src={MenuLogo.src}
              height={12}
              width={18}
              onClick={openMenu}
            />
          ) : (
            HapeLink
          )}
        </LeftContainer>
        <CenterContainer>
          {isMobileWidth ? (
            HapeLink
          ) : (
            <>
              <Link href={Routes.products} style={{ marginRight: "22px" }}>
                <HeaderClickableItem isSelected={isProductPage}>
                  <HeaderTextItem>Products</HeaderTextItem>
                </HeaderClickableItem>
              </Link>
              <Link href={Routes.lookBook}>
                <HeaderClickableItem isSelected={isLookBookPage}>
                  <HeaderTextItem>Look Book</HeaderTextItem>
                </HeaderClickableItem>
              </Link>
            </>
          )}
        </CenterContainer>
        <RightContainer>
          <RightWrapperContainer>
            {!isMobileWidth && (
              <CurrencyDropDownWrapper>
                <CurrencyDropDown />
              </CurrencyDropDownWrapper>
            )}
            <Link href={Routes.cart}>
              <CartWrapper hideMargin={isMobileWidth}>
                <Cart />
              </CartWrapper>
            </Link>
            {!isMobileWidth && <WalletConnect />}
          </RightWrapperContainer>
        </RightContainer>
      </Wrapper>
      <ErrorContainer show={!!menuErrorMessage}>
        <ErrorText>{menuErrorMessage}</ErrorText>
      </ErrorContainer>
    </Column>
  );
};

export default Header;

enum Size {
  Unknown = "UNKNOWN",
  OneSizeFitsAll = "OneSizeFitsAll",
  Small = "S",
  Medium = "M",
  Large = "L",
  ExtraSLarge = "XL",
  ExtraExtraLarge = "XXL",
}

export { Size };

import React, { createContext, useState } from "react";

type MenuErrorMessage = {
  value: string;
  dismissable?: boolean;
};

export type ErrorContextValues = {
  menuErrorMessage: string | null;
  setMenuErrorMessage: ({ value, dismissable }: MenuErrorMessage) => void;
  clearMenuErrorMessage: () => void;
};

export const ErrorContext = createContext<ErrorContextValues>({
  menuErrorMessage: null,
  setMenuErrorMessage: () => {},
  clearMenuErrorMessage: () => {},
});

type Props = {
  children: React.ReactNode;
};

const ERROR_DISMISS_TIME_MS = 10000;

export const ErrorProvider = ({ children }: Props) => {
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  const setMenuErrorMessage = ({
    value,
    dismissable = false,
  }: MenuErrorMessage) => {
    setErrorMessage(value);

    setTimeout(() => {
      if (dismissable) {
        clearMenuErrorMessage();
      }
    }, ERROR_DISMISS_TIME_MS);
  };

  const clearMenuErrorMessage = () => {
    setErrorMessage(null);
  };

  return (
    <ErrorContext.Provider
      value={{
        menuErrorMessage: errorMessage,
        setMenuErrorMessage,
        clearMenuErrorMessage,
      }}
    >
      {children}
    </ErrorContext.Provider>
  );
};

import { Column, Row } from "@components/base_styles";
import { Button } from "@components/button";
import { BREAKPOINTS } from "@styles/breakpoints";
import { colours } from "@styles/theme";
import styled from "styled-components";

const Wrapper = styled(Row)`
  padding: 40px 20px;
  height: 100%;
  width: 100%;
  background-color: ${colours.white}B3;
  z-index: 100;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    flex-direction: column;
    background-color: ${colours.white}E6;
    padding: 20px;
  }
`;

const Separator = styled.div`
  background-color: ${colours.black};
  width: 1px;
  height: 100%;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    width: 100%;
    height: 1px;
  }
`;

const SectionWrapper = styled(Column)`
  justify-content: center;
  align-items: center;
  flex: 1;
  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    width: 100%;
  }
`;

const Title = styled.h1`
  color: ${colours.black};
  font-size: 30px;
  text-align: center;
  margin-bottom: 12px;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    font-size: 22px;
  }
`;

const Subtitle = styled.h3`
  color: ${colours.black};
  font-size: 18px;
  text-align: center;
  margin-bottom: 22px;
  font-weight: 300;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    font-size: 16px;
  }
`;

const ButtonWrapper = styled.div`
  width: 100%;
  max-width: 300px;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    max-width: none;
  }
`;

type Props = {
  onGuestContinue: () => void;
  onWalletContinue: () => void;
};
const FirstLoadOverlay = ({ onGuestContinue, onWalletContinue }: Props) => {
  return (
    <Wrapper>
      <SectionWrapper>
        <Title>Continue as a guest</Title>
        <Subtitle>{`I'm here to just check the Hape apparel`}</Subtitle>
        <ButtonWrapper onClick={onGuestContinue}>
          <Button>Continue</Button>
        </ButtonWrapper>
      </SectionWrapper>
      <Separator />
      <SectionWrapper>
        <Title>Connect your wallet</Title>
        <Subtitle>To access your discount.</Subtitle>
        <ButtonWrapper onClick={onWalletContinue}>
          <Button>Connect your wallet</Button>
        </ButtonWrapper>
      </SectionWrapper>
    </Wrapper>
  );
};

export default FirstLoadOverlay;

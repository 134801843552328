import { createContext, useContext, useEffect, useState } from "react";
import { User } from "@customTypes/user";
import { CartUseCase } from "@use_case/cart_use_case";
import { Cart, EMPTY_CART } from "@customTypes/cart";
import { ErrorContext } from "./error_provider";
import {
  LocalStoreKey,
  deletLocalStoreItem,
  getLocalStoreItem,
  setLocalStoreItem,
} from "@storage/local_storage";
import { CartApiError } from "@customTypes/api_error";
import { SignatureContext } from "./signature_provider";
import { UserType } from "@customTypes/user_type";
import { OrderUseCase } from "@use_case/order_use_case";

const emptyUser: User = {
  firstName: "",
  lastName: "",
  email: "",
  phoneNumber: undefined,
  address: {
    line1: "",
    line2: undefined,
    postcode: "",
    city: "",
    country: "United Kingdom",
  },
};

export type CartContextValues = {
  cartData: Cart;
  cartItemCount: number;
  hasUpdatedCart: boolean;
  deliveryUser: User;
  orderId: string | null;
  paymentSessionId: string | null;
  transactionData: TransactionData | null;
  isLoadingCart: boolean;
  addItemToCart: ({ id, quantity }: CartProps) => void;
  removeCartItem: (id: string) => void;
  updateItemQuantity: ({ id, quantity }: CartProps) => void;
  addDeliveryUser: (user: User) => void;
  clearCart: () => void;
  updateOrderId: (id: string) => void;
  updatePaymentSessionId: (id: string) => void;
  clearOrderId: () => void;
  updateTransactionData: (data: TransactionData) => void;
  fetchCartItems: () => void;
};

export const CartContext = createContext<CartContextValues>({
  cartData: EMPTY_CART,
  cartItemCount: 0,
  hasUpdatedCart: false,
  deliveryUser: emptyUser,
  orderId: null,
  paymentSessionId: null,
  transactionData: null,
  isLoadingCart: false,
  addItemToCart: () => {},
  removeCartItem: () => {},
  updateItemQuantity: () => {},
  addDeliveryUser: () => {},
  clearCart: () => {},
  updateOrderId: () => {},
  updatePaymentSessionId: () => {},
  clearOrderId: () => {},
  updateTransactionData: () => {},
  fetchCartItems: () => {},
});

type Props = {
  children: React.ReactNode;
};

type CartProps = {
  id: string;
  quantity: number;
};

type TransactionData = {
  transactionHash: `0x${string}`;
  contractAddress: string;
};

export const CartProvider = ({ children }: Props) => {
  const storedOrderId = getLocalStoreItem(LocalStoreKey.orderId);
  const [orderId, setOrderId] = useState<string | null>(storedOrderId);
  const [isLoadingCart, setIsLoadingCart] = useState(false);
  const [hasUpdatedCart, setHasUpdatedCart] = useState(false);

  const storedPaymentSessionId = getLocalStoreItem(
    LocalStoreKey.paymentSessionId
  );
  const [paymentSessionId, setPaymentSessionId] = useState<string | null>(
    storedPaymentSessionId
  );
  const [cartData, setCartData] = useState<Cart>(EMPTY_CART);
  const storedDeliveryUser = getLocalStoreItem(LocalStoreKey.deliveryUser);
  const initialDeliveryUser = !!storedDeliveryUser
    ? JSON.parse(storedDeliveryUser)
    : emptyUser;
  const [deliveryUser, setDeliveryUser] = useState<User>(initialDeliveryUser);
  const [transactionData, setTransactionData] =
    useState<TransactionData | null>(null);

  const { setMenuErrorMessage } = useContext(ErrorContext);
  const { userType } = useContext(SignatureContext);

  useEffect(() => {
    const accessToken = getLocalStoreItem(LocalStoreKey.accessToken);
    if (userType != UserType.unknown && !!accessToken) {
      fetchActiveOrder();
      fetchCartItems();
    }
  }, [userType]);

  const fetchActiveOrder = async () => {
    const result = await OrderUseCase.getActiveOrder();
    if (result.ok) {
      updateOrderId(result.value.id);
    } else {
      clearOrderId();
    }
  };

  const fetchCartItems = async () => {
    setIsLoadingCart(true);
    const response = await CartUseCase.getCartData();
    setIsLoadingCart(false);
    if (response.ok) {
      setCartData(response.value);
    } else {
      if (response.error === CartApiError.noBasket) {
        setCartData(EMPTY_CART);
        return;
      }
      handleRequestFailure();
    }
  };

  const addItemToCart = async ({ id, quantity }: CartProps) => {
    const cartItem = cartData.cartItems.find(
      (cartItem) => cartItem.item.id === id
    );
    const newQuantity = (cartItem?.quantity ?? 0) + quantity;

    if (!!cartItem) {
      return updateItemQuantity({
        id: cartItem.item.id,
        quantity: newQuantity,
      });
    }

    const response = await CartUseCase.addItemToCart({
      id,
      quantity: newQuantity,
    });
    if (response.ok) {
      setCartData(response.value);
      notifyItemAddedToCart();
    } else {
      handleRequestFailure();
    }
  };

  const removeCartItem = async (id: string) => {
    const response = await CartUseCase.removeItemFromCart({ id });
    if (response.ok) {
      setCartData(response.value);
    } else {
      handleRequestFailure();
    }
  };

  const updateItemQuantity = async ({ id, quantity }: CartProps) => {
    const response = await CartUseCase.updateCartItem({ id, quantity });
    if (response.ok) {
      setCartData(response.value);
      notifyItemAddedToCart();
    } else {
      handleRequestFailure();
    }
  };

  const handleRequestFailure = () => {
    //TODO: handle failure
    setMenuErrorMessage({
      value: "Unexpected error modifying your cart. Please try again",
      dismissable: true,
    });
  };

  const addDeliveryUser = (user: User) => {
    setDeliveryUser(user);
    setLocalStoreItem(JSON.stringify(user), LocalStoreKey.deliveryUser);
  };

  const clearCart = () => {
    setCartData(EMPTY_CART);
  };

  const updateOrderId = (id: string) => {
    setOrderId(id);
    setLocalStoreItem(id, LocalStoreKey.orderId);
  };

  const updatePaymentSessionId = (id: string) => {
    setPaymentSessionId(id);
    setLocalStoreItem(id, LocalStoreKey.paymentSessionId);
  };

  const clearOrderId = () => {
    setOrderId(null);
    deletLocalStoreItem(LocalStoreKey.orderId);
  };

  const updateTransactionData = (data: TransactionData | null) => {
    setTransactionData(data);
  };

  const notifyItemAddedToCart = () => {
    setHasUpdatedCart(true);
    setTimeout(() => {
      setHasUpdatedCart(false);
    }, 1000);
  };

  return (
    <CartContext.Provider
      value={{
        cartData,
        deliveryUser,
        cartItemCount: cartData.itemCount,
        orderId,
        paymentSessionId,
        transactionData,
        hasUpdatedCart,
        isLoadingCart,
        addItemToCart,
        removeCartItem,
        updateItemQuantity,
        addDeliveryUser,
        clearCart,
        updateOrderId,
        updatePaymentSessionId,
        clearOrderId,
        updateTransactionData,
        fetchCartItems,
      }}
    >
      {children}
    </CartContext.Provider>
  );
};

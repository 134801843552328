import {
  AddToCartProps,
  CartRepository,
  IDProps,
} from "@repository/cart_repository";

class CartUseCase {
  static getCartData = async () => {
    return CartRepository.getCartData();
  };

  static addItemToCart = async ({ id, quantity }: AddToCartProps) => {
    return CartRepository.addItemToCart({ id, quantity });
  };

  static removeItemFromCart = async ({ id }: IDProps) => {
    return CartRepository.removeItemFromCart({ id });
  };

  static updateCartItem = async ({ id, quantity }: AddToCartProps) => {
    return CartRepository.updateCartItem({ id, quantity });
  };
}

export { CartUseCase };

import { ErrorContext } from "@providers/error_provider";
import { MenuContext } from "@providers/menu_provider";
import {
  ERROR_MESSAGE_HEIGHT_DESKTOP,
  ERROR_MESSAGE_HEIGHT_MOBILE,
  HEADER_HEIGHT_DESKTOP,
  HEADER_HEIGHT_DESKTOP_COMPRESSSED,
  HEADER_HEIGHT_MOBILE,
} from "@utils/constants";
import { useContext, useEffect, useState } from "react";
import { useIsMobileWidth } from "./use_is_mobile_width";

const useLayoutData = () => {
  const [hideHeaderShadow, setHideHeaderShadow] = useState(true);
  const { isMobileWidth } = useIsMobileWidth();
  const { menuErrorMessage } = useContext(ErrorContext);
  const {
    closeMenu,
    menuIsOpen,
    menuWidth,
    desktopHeaderHeight,
    setDesktopHeaderHeight,
  } = useContext(MenuContext);

  const menuErrorIsVisible = !!menuErrorMessage;

  const errorMessageHeight = isMobileWidth
    ? ERROR_MESSAGE_HEIGHT_MOBILE
    : ERROR_MESSAGE_HEIGHT_DESKTOP;

  const headerContentHeight = isMobileWidth
    ? HEADER_HEIGHT_MOBILE
    : desktopHeaderHeight;

  const headerContainerHeight =
    headerContentHeight + (menuErrorIsVisible ? errorMessageHeight : 0);

  useEffect(() => {
    if (!isMobileWidth) {
      closeMenu();
    }
  }, [isMobileWidth]);

  const updateHeaderHeight = (scrollYPosition: number) => {
    setHideHeaderShadow(scrollYPosition <= 0);

    const nonNegativeScrollPosition =
      scrollYPosition <= 0 ? 0 : scrollYPosition;

    const valueDifference = HEADER_HEIGHT_DESKTOP - nonNegativeScrollPosition;

    const dynamicHeaderHeight =
      valueDifference < HEADER_HEIGHT_DESKTOP_COMPRESSSED
        ? HEADER_HEIGHT_DESKTOP_COMPRESSSED
        : valueDifference;

    setDesktopHeaderHeight(dynamicHeaderHeight);
  };

  return {
    menuIsOpen,
    closeMenu,
    menuWidth,
    desktopHeaderHeight,
    hideHeaderShadow,
    headerContainerHeight,
    updateHeaderHeight,
  };
};

export { useLayoutData };

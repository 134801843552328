import { ItemData, ItemMap, SizeMap } from "@customTypes/item";
import { Product, ProductData } from "@customTypes/product";
import { Result } from "@customTypes/result";
import { ProductDataSource } from "../data_source/product_data_source";
import {
  ItemMapResponse,
  ProductResponse,
} from "@data_source/types/product_data_response";
import { mapItemResponseToItem } from "./repositpry_mappers/item_response_mapper";
import { ApiError } from "@customTypes/api_error";
import { mapSizeToEnum } from "./repositpry_mappers/item_size_mapper";
import { mapColourToEnum } from "./repositpry_mappers/item_colour_mapper";

class ProductRepository {
  static getProductListing = async (): Promise<Result<ProductData, any>> => {
    try {
      const response = await ProductDataSource.getProductListing();
      if (response.ok) {
        const value = response.value;
        return {
          ok: true,
          value: {
            totalCount: value.total_count,
            results: value.results.map((product) => {
              return ProductRepository._mapProductResponse(product);
            }),
          },
        };
      }
      return response;
    } catch {
      return {
        ok: false,
        error: ApiError.unknown,
      };
    }
  };

  static getProductDetails = async (
    id: string
  ): Promise<Result<ItemData, ApiError>> => {
    try {
      const response = await ProductDataSource.getProductDetails(id);
      if (response.ok) {
        const value = response.value;
        return {
          ok: true,
          value: {
            totalCount: value.total_count,
            results: {
              itemMap: ProductRepository._mapItemMapResponse(
                value.results.item_map
              ),
              availableColours: value.results.available_colours.map((colour) =>
                mapColourToEnum(colour)
              ),
              items: value.results.items.map((item) =>
                mapItemResponseToItem(item)
              ),
            },
          },
        };
      }
      return response;
    } catch {
      return {
        ok: false,
        error: ApiError.unknown,
      };
    }
  };

  private static _mapProductResponse = (product: ProductResponse): Product => {
    return {
      id: product.uid,
      name: product.name,
      description: product.description,
      category: product.category,
      availableColours: product.available_colours.map((colour) =>
        mapColourToEnum(colour)
      ),
      priceUSD: product.price_usd,
      priceGBP: product.price_gbp,
      priceETH: product.price_eth,
      discountPercentage: product.discount_percentage,
      discountedPriceUSD: product.discounted_price_usd,
      discountedPriceGBP: product.discounted_price_gbp,
      discountedPriceETH: product.discounted_price_eth,
      image: product.image,
      eligibleForDiscount: product.eligible_for_discount,
    };
  };

  private static _mapItemMapResponse = (itemMap: ItemMapResponse): ItemMap => {
    let newMap: ItemMap = {};
    Object.keys(itemMap).map((key) => {
      let newChildMap: SizeMap = {};
      const newKey = mapColourToEnum(key);
      const childObject = itemMap[key];
      Object.keys(childObject).forEach((childKey) => {
        const newChildKey = mapSizeToEnum(childKey);
        newChildMap[newChildKey] = childObject[childKey];
      });
      newMap[newKey] = newChildMap;
    });
    return newMap;
  };
}

export { ProductRepository };

import { Product } from "@customTypes/product";
import { createContext, useContext, useEffect, useState } from "react";
import { SignatureContext } from "./signature_provider";
import { UserType } from "@customTypes/user_type";
import { LocalStoreKey, getLocalStoreItem } from "@storage/local_storage";
import { ProductUseCase } from "@use_case/product_use_case";

export type StoreContextValues = {
  fetchProducts: () => void;
  fetchProductsSilently: () => void;
  products: Product[];
  isLoading: boolean;
  hasError: boolean;
};

export const StoreContext = createContext<StoreContextValues>({
  fetchProducts: () => {},
  fetchProductsSilently: () => {},
  products: [],
  isLoading: false,
  hasError: false,
});

type Props = {
  children: React.ReactNode;
};

export const StoreProvider = ({ children }: Props) => {
  const [products, setProducts] = useState<Product[]>([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);
  const { userType } = useContext(SignatureContext);

  const fetchProducts = async () => {
    setIsLoading(true);
    const result = await ProductUseCase.getProductListing();
    setIsLoading(false);
    if (result.ok) {
      setProducts(result.value.results);
    } else {
      setHasError(true);
    }
  };

  const fetchProductsSilently = async () => {
    const accessToken = getLocalStoreItem(LocalStoreKey.accessToken);
    if (userType === UserType.unknown) return;
    if (!accessToken) return;

    const result = await ProductUseCase.getProductListing();
    if (result.ok) {
      setProducts(result.value.results);
    }
  };

  useEffect(() => {
    const accessToken = getLocalStoreItem(LocalStoreKey.accessToken);
    if (userType === UserType.unknown) return;
    if (!accessToken) return;
    fetchProducts();
  }, [userType]);

  return (
    <StoreContext.Provider
      value={{
        products,
        isLoading,
        hasError,
        fetchProducts,
        fetchProductsSilently,
      }}
    >
      {children}
    </StoreContext.Provider>
  );
};

import { Row } from "@components/base_styles";
import { Currency } from "@customTypes/currency";
import { CurrencyContext } from "@providers/currency_provider";
import { colours } from "@styles/theme";
import Image from "next/image";
import { useContext, useEffect, useRef, useState } from "react";
import styled from "styled-components";
import GBPIcon from "../../public/gbp_icon.png";
import USDIcon from "../../public/usd_icon.png";
import ETHIconWhite from "../../public/eth_icon_white.png";
import ETHIcon from "../../public/eth_icon.png";
import DownChevron from "../../public/down_chevron.svg";
import DownChevronWhite from "../../public/down_chevron_white.svg";

interface ThemeInterface {
  dark: boolean;
}
const Wrapper = styled.div<ThemeInterface>`
  position: relative;
  width: 100%;
  background-color: ${(props) =>
    `${props.dark ? `${colours.black}` : `${colours.white}`}`};
`;

interface IsOpen {
  isOpen: boolean;
}
const SelectedOptionWrapper = styled(Row)<IsOpen>`
  cursor: pointer;
  width: 100%;
`;

const DropDownWrapper = styled.div<IsOpen & ThemeInterface>`
  position: absolute;
  left: 0;
  right: 0;
  top: 100%;
  background-color: ${(props) =>
    `${props.dark ? colours.black : colours.white}`};
  box-shadow: ${(props) =>
    `${props.isOpen ? "0px 12px 16px rgba(0, 100, 0, 0.15)" : "none"}`};
`;

const Divider = styled.div<ThemeInterface>`
  height: 0.5px;
  background-color: ${(props) =>
    props.dark ? `${colours.offBlack}` : `${colours.lightGrey}`};
  margin: 0 4px;
`;

const Option = styled.div<ThemeInterface>`
  padding: 8px;
  color: ${(props) =>
    `${props.dark ? `${colours.white}` : `${colours.black}`}`};
  background-color: transparent;
  font-size: 14px;
  margin-right: auto;
`;

const DropDownOptionWrapper = styled.div<ThemeInterface>`
  cursor: pointer;
  background-color: ${(props) =>
    `${props.dark ? `${colours.black}` : `${colours.white}`}`};

  :hover {
    background-color: ${(props) =>
      `${props.dark ? `${colours.offBlack}` : `${colours.lightGrey}`}`};
  }
`;

const OptionPadding = styled(Row)`
  padding: 2px 8px;
  flex: 1;
`;

const CurrencyOptionContainer = styled.div``;

type Props = {
  dark?: boolean;
};
const CurrencyDropDown = ({ dark = false }: Props) => {
  const { activeCurrency, setActiveCurrency } = useContext(CurrencyContext);
  const [isOpen, setIsOpen] = useState(false);
  const ref = useRef<HTMLDivElement>(null);

  const handleClickOutside = (event: MouseEvent) => {
    if (isOpen) {
      if (ref.current && !ref.current.contains(event.target as Node)) {
        setIsOpen(false);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref, isOpen]);

  const onChange = (currency: Currency) => {
    setActiveCurrency(currency);
    setIsOpen(false);
  };

  const mapValue = (currency: Currency) => {
    switch (currency) {
      case Currency.eth:
        return "ETH";
      case Currency.usd:
        return "USD";
      case Currency.gbp:
        return "GBP";
    }
  };

  const mapIconSrc = (currency: Currency, dark: boolean) => {
    switch (currency) {
      case Currency.eth:
        return dark ? ETHIconWhite : ETHIcon;
      case Currency.usd:
        return USDIcon;
      case Currency.gbp:
        return GBPIcon;
    }
  };

  const onDoprDownClick = () => {
    setIsOpen(!isOpen);
  };

  return (
    <Wrapper ref={ref} dark={dark}>
      <SelectedOptionWrapper isOpen={isOpen} onClick={onDoprDownClick}>
        <OptionPadding>
          <Image
            src={mapIconSrc(activeCurrency, dark)}
            height={20}
            width={20}
            alt=""
          />
          <Option dark={dark}>{mapValue(activeCurrency)}</Option>
          <Image
            src={dark ? DownChevronWhite : DownChevron}
            height={10}
            width={10}
            alt=""
          />
        </OptionPadding>
      </SelectedOptionWrapper>
      {isOpen && (
        <DropDownWrapper isOpen={isOpen} dark={dark}>
          {Object.keys(Currency)
            .filter((key) => !!isNaN(Number(Currency[key as any])))
            .map((currency) => {
              const parsedCurrency = parseInt(currency);
              if (parsedCurrency === activeCurrency) return null;
              return (
                <CurrencyOptionContainer
                  key={`currency-drop-down-item-${currency}`}
                >
                  <Divider dark={dark} />
                  <DropDownOptionWrapper
                    onClick={() => onChange(parsedCurrency)}
                    dark={dark}
                  >
                    <OptionPadding>
                      <Image
                        src={mapIconSrc(parsedCurrency, dark)}
                        height={20}
                        width={20}
                        alt=""
                      />
                      <Option dark={dark}>{mapValue(parsedCurrency)}</Option>
                    </OptionPadding>
                  </DropDownOptionWrapper>
                </CurrencyOptionContainer>
              );
            })}
        </DropDownWrapper>
      )}
    </Wrapper>
  );
};

export default CurrencyDropDown;

import { Item } from "./item";

export type Cart = {
  id: string;
  uniqueItemCount: number;
  itemCount: number;
  cartPrice: CartPrice;
  cartItems: CartItem[];
};

export type CartPrice = {
  priceGBP: number;
  priceUSD: number;
  priceETH: number;
  discountedPriceGBP: number;
  discountedPriceUSD: number;
  discountedPriceETH: number;
  discountedGBP?: number;
  discountedUSD?: number;
  discountedETH?: number;
};

export type CartItem = {
  id: string;
  item: Item;
  quantity: number;
  basketId: string;
  priceUSD: number;
  priceGBP: number;
  priceETH: number;
  eligibleForDiscount: boolean;
  discountedPriceUSD: number;
  discountedPriceGBP: number;
  discountedPriceETH: number;
};

export const EMPTY_CART: Cart = {
  id: "",
  uniqueItemCount: 0,
  itemCount: 0,
  cartPrice: {
    priceGBP: 0,
    priceUSD: 0,
    priceETH: 0,
    discountedPriceGBP: 0,
    discountedPriceUSD: 0,
    discountedPriceETH: 0,
    discountedGBP: 0,
    discountedUSD: 0,
    discountedETH: 0,
  },
  cartItems: [],
};

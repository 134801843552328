import { ApiError } from "@customTypes/api_error";
import { Result } from "@customTypes/result";
import api from "@network/api";
import { Endpoints } from "@network/endpoints";
import { TokenService } from "./token_service";
import { LoginReponse } from "./types/auth_data_response";

type LoginProps = {
  walletAddress: string;
  signature: string;
  message: string;
};

class AuthDataSource {
  static walletLogin = async ({
    walletAddress,
    signature,
    message,
  }: LoginProps): Promise<Result<LoginReponse, ApiError>> => {
    try {
      const response = await api.post(Endpoints.authorizeWalletUser, {
        public_key: walletAddress,
        signature: signature,
        message: message,
      });
      if (response.status === 200) {
        if (response.data.token) {
          TokenService.updateLocalAccessToken(response.data.token);
        }
        return {
          ok: true,
          value: response.data,
        };
      }
      return {
        ok: false,
        error: ApiError.unknown,
      };
    } catch (error) {
      return {
        ok: false,
        error: ApiError.unknown,
      };
    }
  };

  static guestLogin = async (): Promise<Result<LoginReponse, ApiError>> => {
    try {
      const response = await api.post(Endpoints.authorizeGuestUser);

      if (response.status === 200) {
        if (response.data.token) {
          TokenService.updateLocalAccessToken(response.data.token);
        }
        return {
          ok: true,
          value: response.data,
        };
      }
      return {
        ok: false,
        error: ApiError.unknown,
      };
    } catch (error) {
      return {
        ok: false,
        error: ApiError.unknown,
      };
    }
  };

  static logOut = () => {
    TokenService.deleteLocalAccessToken();
  };
}

export { AuthDataSource };

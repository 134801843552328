import { baseUrl } from "../chain_network/chain_network";
import axios from "axios";
import { TokenService } from "../data_source/token_service";
import { publicEndpoints } from "./endpoints";
import { isProduction } from "@utils/constants";

const instance = axios.create({
  baseURL: baseUrl,
  headers: {
    "Content-Type": "application/json",
  },
});

instance.interceptors.request.use(
  (config) => {
    if (typeof window !== "undefined") {
      const token = TokenService.getLocalAccessToken();
      if (token) {
        config.headers["Authorization"] = `Token ${token}`;
      }
    }
    const endpoint = config.url ?? "unknown";
    if (publicEndpoints.includes(endpoint)) {
      config.headers["x-api-key"] = isProduction
        ? `af9c925a-dbc8-4375-be3d-8e80e07f2cea`
        : `jascro-test-123`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

export default instance;

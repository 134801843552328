import { WalletContext } from "@providers/wallet_provider";
import { useContext, useEffect, useState } from "react";
import {
  getLocalStoreItem,
  LocalStoreKey,
  setLocalStoreItem,
} from "../../storage/local_storage";
import { isStandbyMode } from "@utils/constants";

const useFirstLoadData = () => {
  const [isFirstLoad, setIsFirstLoad] = useState(false);
  const { isConnected, openWalletConnectModal } = useContext(WalletContext);

  const showFirstLoadOverlay = isFirstLoad;

  useEffect(() => {
    const isFirstVisit = getLocalStoreItem(LocalStoreKey.firstVisit);
    setIsFirstLoad(isFirstVisit === null);
  }, []);

  useEffect(() => {
    if (isConnected && isFirstLoad) {
      setIsFirstLoad(false);
      setLocalStoreItem("true", LocalStoreKey.firstVisit);
    }
  }, [isConnected]);

  const onWalletContinue = () => {
    openWalletConnectModal();
  };

  const onGuestContinue = async () => {
    setLocalStoreItem("true", LocalStoreKey.firstVisit);
    setIsFirstLoad(false);
  };

  return {
    onGuestContinue,
    onWalletContinue,
    showFirstLoadOverlay: showFirstLoadOverlay && !isStandbyMode,
  };
};

export { useFirstLoadData };

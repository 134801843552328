import { ApiError, CartApiError } from "@customTypes/api_error";
import { Cart, CartItem, CartPrice } from "@customTypes/cart";
import { Result } from "@customTypes/result";
import { CartDataSource } from "@data_source/cart_data_source";
import {
  CartItemResponse,
  CartPriceResponse,
  CartResponse,
} from "@data_source/types/cart_data_response";
import { mapItemResponseToItem } from "./repositpry_mappers/item_response_mapper";

export type AddToCartProps = {
  id: string;
  quantity: number;
};

export type IDProps = {
  id: string;
};

class CartRepository {
  static getCartData = async (): Promise<Result<Cart, CartApiError>> => {
    const response = await CartDataSource.getCartData();
    if (response.ok) {
      return {
        ok: true,
        value: CartRepository._mapCartResponse(response.value),
      };
    }
    return { ok: false, error: response.error };
  };

  static addItemToCart = async ({
    id,
    quantity,
  }: AddToCartProps): Promise<Result<Cart, ApiError>> => {
    const response = await CartDataSource.addItemToCart({ id, quantity });
    if (response.ok) {
      return {
        ok: true,
        value: CartRepository._mapCartResponse(response.value),
      };
    }
    return { ok: false, error: ApiError.unknown };
  };

  static removeItemFromCart = async ({
    id,
  }: IDProps): Promise<Result<Cart, ApiError>> => {
    const response = await CartDataSource.removeItemFromCart({ id });
    if (response.ok) {
      return {
        ok: true,
        value: CartRepository._mapCartResponse(response.value),
      };
    }
    return { ok: false, error: ApiError.unknown };
  };

  static updateCartItem = async ({
    id,
    quantity,
  }: AddToCartProps): Promise<Result<Cart, ApiError>> => {
    const response = await CartDataSource.updateCartItem({ id, quantity });
    if (response.ok) {
      return {
        ok: true,
        value: CartRepository._mapCartResponse(response.value),
      };
    }
    return { ok: false, error: ApiError.unknown };
  };

  static _mapCartResponse = (cartResponse: CartResponse): Cart => {
    return {
      id: cartResponse.uid,
      uniqueItemCount: cartResponse.unique_item_count,
      itemCount: cartResponse.item_count,
      cartPrice: CartRepository._mapToCartPrice(cartResponse.basket_price),
      cartItems: cartResponse.basket_items.map((basketItem) =>
        CartRepository._mapToCartItem(basketItem)
      ),
    };
  };

  private static _mapToCartPrice = (
    cartPrice: CartPriceResponse
  ): CartPrice => {
    return {
      priceUSD: cartPrice.price_usd,
      priceGBP: cartPrice.price_gbp,
      priceETH: cartPrice.price_eth,
      discountedPriceUSD: cartPrice.discounted_price_usd,
      discountedPriceGBP: cartPrice.discounted_price_gbp,
      discountedPriceETH: cartPrice.discounted_price_eth,
      discountedUSD: cartPrice.discount_usd,
      discountedGBP: cartPrice.discount_gbp,
      discountedETH: cartPrice.discount_eth,
    };
  };

  private static _mapToCartItem = (
    cartItemResponse: CartItemResponse
  ): CartItem => {
    return {
      id: cartItemResponse.uid,
      item: mapItemResponseToItem(cartItemResponse.item),
      quantity: cartItemResponse.quantity,
      basketId: cartItemResponse.basket_uid,
      priceUSD: cartItemResponse.price_usd,
      priceGBP: cartItemResponse.price_gbp,
      priceETH: cartItemResponse.price_eth,
      eligibleForDiscount: cartItemResponse.eligible_for_discount,
      discountedPriceUSD: cartItemResponse.discounted_price_usd,
      discountedPriceGBP: cartItemResponse.discounted_price_gbp,
      discountedPriceETH: cartItemResponse.discounted_price_eth,
    };
  };
}

export { CartRepository };

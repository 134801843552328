import { colours } from "@styles/theme";
import Image from "next/image";
import styled from "styled-components";
import { Clickable, Column, LinkA, LinkText, Row } from "../base_styles";
import { WalletConnect } from "../wallet_connect";

import TwitterIcon from "../../public/twitter_icon_white.svg";
import InstagramIcon from "../../public/instagram_icon_white.svg";
import DiscordIcon from "../../public/discord_icon_white.svg";
import { IconRow } from "./icon_row";
import { useContext } from "react";
import { MenuContext } from "@providers/menu_provider";
import { useRouter } from "next/router";
import { Routes } from "@router/routes";
import {
  DISCORD_URL,
  INSTAGRAM_URL,
  TERMS_AND_CONDITIONS_URL,
  TWITTER_URL,
} from "@utils/constants";
import CurrencyDropDown from "@components/currency_dropdown";
import { BREAKPOINTS } from "@styles/breakpoints";

const SIDE_PADDING = "16px";

const Wrapper = styled(Column)`
  justify-content: center;
  height: 100%;
  background-color: ${colours.black};

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    @media (orientation: landscape) {
      height: auto;
    }
  }
`;

const ContentWrapper = styled(Column)`
  justify-content: center;
  height: 100%;
  padding: 0 ${SIDE_PADDING};
`;
const WalletRow = styled(Row)`
  justify-content: space-between;
  align-items: center;
  flex: 1;
  margin: 30px ${SIDE_PADDING};
`;

const WalletButtonWrapper = styled.div`
  justify-content: center;
  display: flex;
  justify-content: flex-start;
`;

const Line = styled.div`
  background-color: ${colours.lightGrey};
  height: 1px;
`;

const Footer = styled(Column)`
  margin-top: auto;
  margin-bottom: 52px;
  justify-content: center;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    @media (orientation: landscape) {
      padding-top: 50px;
    }
  }
`;

const SocialContainer = styled(Row)`
  justify-content: flex-start;
  margin-bottom: 22px;
`;

const CopyrightText = styled.p`
  font-size: 12px;
  color: ${colours.white};
  text-align: left;
  margin-bottom: 10px;
  font-weight: 300;
  white-space: nowrap;
`;

const TermsLink = styled(LinkText)`
  font-size: 12px;
  text-align: center;
  font-weight: 300;
  align-self: left;
  color: ${colours.white};
  white-space: nowrap;
`;

const CurrencyDropDownWrapper = styled.div`
  margin-top: 22px;
  margin-bottom: 40px;
  border-bottom: 1px solid ${colours.offBlack};
`;

interface SocialLinkInterface {
  padded?: boolean;
}
const SocialLink = styled(LinkA)<SocialLinkInterface>`
  margin: ${(props) => (props.padded ? " 0 20px" : 0)};
`;

const SideMenu = () => {
  const { closeMenu } = useContext(MenuContext);
  const router = useRouter();

  const onHomeClick = () => {
    closeMenu();
    router.push(Routes.home);
  };
  const onProductsClick = () => {
    closeMenu();
    router.push(Routes.products);
  };
  const onLookBookClick = () => {
    closeMenu();
    router.push(Routes.lookBook);
  };
  return (
    <Wrapper>
      <WalletRow>
        <WalletButtonWrapper>
          <WalletConnect reverse light />
        </WalletButtonWrapper>
        <Clickable onClick={closeMenu}>
          <Image height={12} width={12} alt="" src={"/cross_icon.svg"} />
        </Clickable>
      </WalletRow>
      <Line />
      <ContentWrapper>
        <IconRow onClick={onHomeClick} text="HOME" />
        <IconRow onClick={onLookBookClick} text="LOOK BOOK" />
        <IconRow onClick={onProductsClick} text="PRODUCTS" />
        <Footer>
          <CurrencyDropDownWrapper>
            <CurrencyDropDown dark />
          </CurrencyDropDownWrapper>
          <SocialContainer>
            <SocialLink href={TWITTER_URL} target="_blank">
              <Image src={TwitterIcon.src} height={18} width={22} alt="" />
            </SocialLink>
            <SocialLink padded href={INSTAGRAM_URL} target="_blank">
              <Image src={InstagramIcon.src} height={18} width={18} alt="" />
            </SocialLink>
            <SocialLink href={DISCORD_URL} target="_blank">
              <Image src={DiscordIcon.src} height={18} width={24} alt="" />
            </SocialLink>
          </SocialContainer>
          <CopyrightText>2022 Hape. All Rights Reseved.</CopyrightText>
          <TermsLink
            href={TERMS_AND_CONDITIONS_URL}
            target="_blank"
            color={colours.black}
          >
            Terms and conditions
          </TermsLink>
        </Footer>
      </ContentWrapper>
    </Wrapper>
  );
};
export { SideMenu };

import { AuthRepository } from "../repository/auth_repository";

type LoginProps = {
  walletAddress: string;
  signature: string;
  message: string;
};

class AuthUseCase {
  static walletLogin = async ({
    walletAddress,
    signature,
    message,
  }: LoginProps) => {
    return AuthRepository.walletLogin({ walletAddress, signature, message });
  };

  static guestLogin = () => {
    return AuthRepository.guestLogin();
  };

  static logOut = () => {
    return AuthRepository.logOut();
  };
}

export { AuthUseCase };

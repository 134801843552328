import { ApiError } from "@customTypes/api_error";
import { Result } from "@customTypes/result";
import { AuthDataSource } from "@data_source/auth_data_source";
import { LoginReponse } from "@data_source/types/auth_data_response";

type LoginProps = {
  walletAddress: string;
  signature: string;
  message: string;
};

class AuthRepository {
  static walletLogin = async ({
    walletAddress,
    signature,
    message,
  }: LoginProps): Promise<Result<LoginReponse, ApiError>> => {
    try {
      return AuthDataSource.walletLogin({ walletAddress, signature, message });
    } catch {
      return {
        ok: false,
        error: ApiError.unknown,
      };
    }
  };

  static guestLogin = async (): Promise<Result<LoginReponse, ApiError>> => {
    try {
      return AuthDataSource.guestLogin();
    } catch {
      return {
        ok: false,
        error: ApiError.unknown,
      };
    }
  };

  static logOut = () => {
    return AuthDataSource.logOut();
  };
}

export { AuthRepository };

import { isProduction } from "@utils/constants";
import ChainConfig from "./chain_config.json";

const networkEnvironment = isProduction
  ? ChainConfig.config.network.prod
  : ChainConfig.config.network.dev;

const devChainId = ChainConfig.config.network.dev.chainId;

export const isDev = networkEnvironment.chainId === devChainId;
export const authorisedChainId = networkEnvironment.chainId;
export const authorisedNetwork = networkEnvironment.name;

export const baseUrl = networkEnvironment.baseUrl;

export const apparelClaimingContractAddress =
  networkEnvironment.apparelClaimingAddress as `0x${string}`;

export const etherscanTransactionURL = (hash: string) => {
  return networkEnvironment.etherscanBaseURL + "/tx/" + hash;
};

export const claimedApparelOpenseaUrl =
  networkEnvironment.claimedApparelOpenseaUrl;

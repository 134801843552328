import { Result } from "@customTypes/result";
import {
  CreateOrderDataInput,
  OrderDataResponse,
  OrderTokensResponse,
  UpdateOrderDataInput,
} from "./types/order_data_response";
import api from "@network/api";
import { Endpoints } from "@network/endpoints";
import { ApiError } from "@customTypes/api_error";

class OrderDataSource {
  static getOrder = async (
    id: string
  ): Promise<Result<OrderDataResponse, ApiError>> => {
    try {
      const response = await api.get(Endpoints.getOrder(id));

      if (response.status === 200) {
        return {
          ok: true,
          value: response.data,
        };
      }
      return {
        ok: false,
        error: ApiError.unknown,
      };
    } catch (error) {
      console.log({ error });

      return {
        ok: false,
        error: ApiError.unknown,
      };
    }
  };

  static createOrder = async (
    createOrderDataInput: CreateOrderDataInput
  ): Promise<Result<OrderDataResponse, ApiError>> => {
    try {
      const response = await api.post(
        Endpoints.createOrder,
        createOrderDataInput
      );
      if (response.status >= 200 && response.status < 300) {
        return {
          ok: true,
          value: response.data,
        };
      }
      return {
        ok: false,
        error: ApiError.unknown,
      };
    } catch (error) {
      console.log({ error });

      return {
        ok: false,
        error: ApiError.unknown,
      };
    }
  };

  static updateOrder = async (
    orderId: string,
    updateOrderDataInput: UpdateOrderDataInput
  ): Promise<Result<OrderDataResponse, ApiError>> => {
    try {
      const response = await api.patch(
        Endpoints.updateOrder(orderId),
        updateOrderDataInput
      );
      if (response.status === 200) {
        return {
          ok: true,
          value: response.data,
        };
      }
      return {
        ok: false,
        error: ApiError.unknown,
      };
    } catch (error) {
      console.log({ error });

      return {
        ok: false,
        error: ApiError.unknown,
      };
    }
  };

  static getActiveOrder = async (): Promise<
    Result<OrderDataResponse, ApiError>
  > => {
    try {
      const response = await api.get(Endpoints.getUserOrder);
      if (response.status === 200) {
        return {
          ok: true,
          value: response.data,
        };
      }
      return {
        ok: false,
        error: ApiError.unknown,
      };
    } catch (error) {
      console.log({ error });

      return {
        ok: false,
        error: ApiError.unknown,
      };
    }
  };

  static getTokensInOrder = async (
    orderId: string
  ): Promise<Result<OrderTokensResponse, ApiError>> => {
    try {
      const response = await api.get(Endpoints.getTokens(orderId));
      if (response.status === 200) {
        return {
          ok: true,
          value: response.data,
        };
      }
      return {
        ok: false,
        error: ApiError.unknown,
      };
    } catch (error) {
      return {
        ok: false,
        error: ApiError.unknown,
      };
    }
  };
}

export { OrderDataSource };

import {
  configureChains,
  Connector,
  createClient,
  useAccount,
  WagmiConfig,
} from "wagmi";
import { mainnet, sepolia } from "wagmi/chains";
import {
  EthereumClient,
  w3mConnectors,
  w3mProvider,
} from "@web3modal/ethereum";
import { useWeb3Modal, Web3Modal } from "@web3modal/react";
import { createContext, useEffect, useState } from "react";
import { authorisedChainId, isDev } from "../chain_network/chain_network";
import { watchNetwork } from "@wagmi/core";

if (!process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID) {
  throw new Error(
    "You need to provide NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID env variable"
  );
}

const chains = isDev ? [sepolia] : [mainnet];
const walletConnectProjectId =
  process.env.NEXT_PUBLIC_WALLET_CONNECT_PROJECT_ID;

// @ts-ignore
const { provider } = configureChains(chains, [
  w3mProvider({ projectId: walletConnectProjectId! }),
]);

const wagmiClient = createClient({
  autoConnect: true,
  connectors: w3mConnectors({
    version: 1,
    chains,
    projectId: walletConnectProjectId!,
  }),
  provider,
});

const ethereumClient = new EthereumClient(wagmiClient, chains);

type WalletContextValues = {
  walletAddress: string;
  isConnected: boolean;
  isValidChain: boolean;
  chainId: number;
  connector: Connector<any, any, any> | undefined;
  openWalletConnectModal: () => Promise<void>;
};

export const WalletContext = createContext<WalletContextValues>({
  walletAddress: "",
  isConnected: false,
  isValidChain: true,
  openWalletConnectModal: async () => {},
  connector: undefined,
  chainId: 0,
});

type Props = {
  children: React.ReactNode;
};

const WalletProvider = ({ children }: Props) => {
  const [isValidChain, setIsValidChain] = useState(true);
  const [chainId, setChainId] = useState<number | undefined>();
  const { isConnected, address, connector } = useAccount();
  const { open: openWalletConnectModal } = useWeb3Modal();
  const walletAddress = address ?? "";

  const unWatch = watchNetwork((network) => {
    const chainId = network.chain?.id;

    setChainId(chainId);
    if (isConnected) {
      setIsValidChain(chainId === authorisedChainId);
    }
  });

  useEffect(() => {
    return () => unWatch();
  }, []);

  return (
    <WalletContext.Provider
      value={{
        walletAddress,
        isConnected,
        isValidChain,
        chainId: chainId ?? 0,
        connector: connector,
        openWalletConnectModal,
      }}
    >
      <WagmiConfig client={wagmiClient}>{children}</WagmiConfig>
      <Web3Modal
        projectId={walletConnectProjectId!}
        ethereumClient={ethereumClient}
      />
    </WalletContext.Provider>
  );
};

export { WalletProvider };

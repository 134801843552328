import { Item } from "@customTypes/item";
import { ItemResponse } from "@data_source/types/product_data_response";
import { mapSizeToEnum } from "./item_size_mapper";
import { mapColourToEnum } from "./item_colour_mapper";

export const mapItemResponseToItem = (itemResponse: ItemResponse): Item => {
  return {
    id: itemResponse.uid,
    name: itemResponse.name,
    description: itemResponse.description,
    details: itemResponse.details,
    careInstructions: itemResponse.care_instructions,
    sizeAndFit: itemResponse.size_and_fit,
    sizeGuide: itemResponse.size_guide,
    sizeGuideMobile: itemResponse.size_guide_mobile,
    category: itemResponse.category,
    colour: mapColourToEnum(itemResponse.colour),
    size: mapSizeToEnum(itemResponse.size),
    remainingStock: itemResponse.remaining_stock,
    priceGBP: itemResponse.price_gbp,
    priceUSD: itemResponse.price_usd,
    priceETH: itemResponse.price_eth,
    discountPercentage: itemResponse.discount_percentage,
    eligibleForDiscount: itemResponse.eligible_for_discount,
    discountedPriceGBP: itemResponse.discounted_price_gbp,
    discountedPriceUSD: itemResponse.discounted_price_usd,
    discountedPriceETH: itemResponse.discounted_price_eth,
    images: itemResponse.images,
  };
};

import { ApiError } from "@customTypes/api_error";
import {
  CreateOrderInput,
  Order,
  OrderDelivery,
  OrderRecipient,
  OrderStatus,
  OrderTokens,
  UpdateOrderInput,
} from "@customTypes/order";
import { Result } from "@customTypes/result";
import { OrderDataSource } from "@data_source/order_data_source";
import { CartRepository } from "./cart_repository";
import {
  OrderDataResponse,
  OrderDeliveryResponse,
  OrderRecipientResponse,
} from "@data_source/types/order_data_response";
import {
  LocalStoreKey,
  deletLocalStoreItem,
  setLocalStoreItem,
} from "@storage/local_storage";

class OrderRepository {
  static getOrder = async (id: string): Promise<Result<Order, ApiError>> => {
    const response = await OrderDataSource.getOrder(id);
    if (response.ok) {
      const value = response.value;

      return {
        ok: true,
        value: OrderRepository._mapOrderResponse(value),
      };
    }
    return response;
  };

  static createOrder = async (
    orderInput: CreateOrderInput
  ): Promise<Result<Order, ApiError>> => {
    const response = await OrderDataSource.createOrder({
      basket_uid: orderInput.basketId,
      order_recipient: {
        first_name: orderInput.orderRecipient.firstName,
        last_name: orderInput.orderRecipient.lastName,
        email: orderInput.orderRecipient.email,
        phone_number: orderInput.orderRecipient.phoneNumber,
      },
      delivery: {
        address: {
          address_line_1: orderInput.delivery.address.addressLine1,
          address_line_2: orderInput.delivery.address.addressLine2,
          city: orderInput.delivery.address.city,
          country: orderInput.delivery.address.country,
          zip_code: orderInput.delivery.address.zipCode,
        },
        delivery_notes: orderInput.delivery.deliveryNotes,
      },
    });

    if (response.ok) {
      const value = response.value;

      return {
        ok: true,
        value: OrderRepository._mapOrderResponse(value),
      };
    }
    return {
      ok: false,
      error: ApiError.unknown,
    };
  };

  static updateOrder = async (
    orderInput: UpdateOrderInput
  ): Promise<Result<Order, ApiError>> => {
    const response = await OrderDataSource.updateOrder(orderInput.orderId, {
      order_recipient: {
        first_name: orderInput.orderRecipient.firstName,
        last_name: orderInput.orderRecipient.lastName,
        email: orderInput.orderRecipient.email,
        phone_number: orderInput.orderRecipient.phoneNumber,
      },
      delivery: {
        address: {
          address_line_1: orderInput.delivery.address.addressLine1,
          address_line_2: orderInput.delivery.address.addressLine2,
          city: orderInput.delivery.address.city,
          country: orderInput.delivery.address.country,
          zip_code: orderInput.delivery.address.zipCode,
        },
        delivery_notes: orderInput.delivery.deliveryNotes,
      },
    });

    if (response.ok) {
      const value = response.value;

      return {
        ok: true,
        value: OrderRepository._mapOrderResponse(value),
      };
    }
    return {
      ok: false,
      error: ApiError.unknown,
    };
  };

  static getActiveOrder = async (): Promise<Result<Order, ApiError>> => {
    const response = await OrderDataSource.getActiveOrder();
    if (response.ok) {
      const value = response.value;
      setLocalStoreItem(value.uid, LocalStoreKey.orderId);
      return {
        ok: true,
        value: OrderRepository._mapOrderResponse(value),
      };
    }
    deletLocalStoreItem(LocalStoreKey.orderId);

    return {
      ok: false,
      error: ApiError.unknown,
    };
  };

  static getTokensInOrder = async (
    orderId: string
  ): Promise<Result<OrderTokens, ApiError>> => {
    const response = await OrderDataSource.getTokensInOrder(orderId);
    if (response.ok) {
      return {
        ok: true,
        value: {
          tokens: response.value.token_ids,
        },
      };
    }
    return response;
  };

  private static _mapOrderResponse(response: OrderDataResponse): Order {
    return {
      id: response.uid,
      status: OrderRepository._mapOrderStatus(response.status),
      cart: CartRepository._mapCartResponse(response.basket),
      orderRecipient: OrderRepository._mapOrderRecipientResponse(
        response.order_recipient
      ),
      delivery: OrderRepository._mapDeliveryResponse(response.delivery),
      totalPriceGBP: response.total_price_gbp,
      totalPriceUSD: response.total_price_usd,
      totalPriceETH: response.total_price_eth,
    };
  }

  private static _mapOrderRecipientResponse(
    orderRecipientResponse: OrderRecipientResponse
  ): OrderRecipient {
    return {
      firstName: orderRecipientResponse.first_name,
      lastName: orderRecipientResponse.last_name,
      email: orderRecipientResponse.email,
      phoneNumber: orderRecipientResponse.phone_number,
    };
  }

  private static _mapDeliveryResponse(
    deliveryResponse: OrderDeliveryResponse
  ): OrderDelivery {
    return {
      address: {
        addressLine1: deliveryResponse.address.address_line_1,
        addressLine2: deliveryResponse.address.address_line_2,
        city: deliveryResponse.address.city,
        country: deliveryResponse.address.country,
        zipCode: deliveryResponse.address.zip_code,
      },
      deliveryNotes: deliveryResponse.delivery_notes,
      deliveryFeeGBP: deliveryResponse.delivery_fee_gbp,
      deliveryFeeUSD: deliveryResponse.delivery_fee_usd,
      deliveryFeeETH: deliveryResponse.delivery_fee_eth,
    };
  }

  private static _mapOrderStatus(orderStatus: string): OrderStatus {
    switch (orderStatus) {
      case "IN_PROGRESS":
        return OrderStatus.inProgress;
      case "ORDER_COMPLETED":
        return OrderStatus.awaitingShipping;
      case "AWAITING_SHIPPING":
        return OrderStatus.orderCompleted;
      case "PROCESSING_PAYMENT":
        return OrderStatus.processingPayment;
      default:
        return OrderStatus.inProgress;
    }
  }
}

export { OrderRepository };

import { ClickableRow } from "@components/base_styles";
import styled from "styled-components";

export const HeaderTextItem = styled.h3`
  color: black;
  font-size: 14px;
  font-weight: 400;
`;

interface HeaderInterface {
  isSelected?: boolean;
}
export const HeaderClickableItem = styled(ClickableRow)<HeaderInterface>`
  border-bottom: 2px solid
    ${(props) => (props.isSelected ? "black" : "transparent")};
  padding: 3px 2px;
  font-weight: 400;

  :hover {
    border-bottom: 2px solid black;
  }
`;

import {
  deletLocalStoreItem,
  getLocalStoreItem,
  LocalStoreKey,
  setLocalStoreItem,
} from "@storage/local_storage";

class TokenService {
  static getLocalAccessToken = () => {
    const accessToken = getLocalStoreItem(LocalStoreKey.accessToken);
    return accessToken;
  };

  static updateLocalAccessToken = (token: string) => {
    setLocalStoreItem(token, LocalStoreKey.accessToken);
  };

  static deleteLocalAccessToken = () => {
    deletLocalStoreItem(LocalStoreKey.accessToken);
  };
}

export { TokenService };

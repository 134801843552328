import { Cart } from "./cart";

export type Order = {
  id: string;
  status: OrderStatus;
  cart: Cart;
  orderRecipient: OrderRecipient;
  delivery: OrderDelivery;
  totalPriceGBP: number;
  totalPriceUSD: number;
  totalPriceETH: number;
};

export type OrderDelivery = {
  address: OrderDeliveryAddress;
  deliveryNotes: string;
  deliveryFeeGBP: number;
  deliveryFeeUSD: number;
  deliveryFeeETH: number;
};

type OrderDeliveryAddress = {
  addressLine1: string;
  addressLine2?: string;
  city: string;
  country: string;
  zipCode: string;
};

export type OrderRecipient = {
  firstName: string;
  lastName: string;
  email: string;
  phoneNumber?: string;
};

export enum OrderStatus {
  inProgress,
  processingPayment,
  awaitingShipping,
  orderCompleted,
}

export type OrderTokens = {
  tokens: number[];
};

// ------------------------------
// INPUTS

export type CreateOrderInput = {
  basketId: string;
  orderRecipient: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string;
  };
  delivery: {
    address: {
      addressLine1: string;
      addressLine2?: string;
      city: string;
      country: string;
      zipCode: string;
    };
    deliveryNotes?: string;
  };
};

export type UpdateOrderInput = {
  orderId: string;
  orderRecipient: {
    firstName: string;
    lastName: string;
    email: string;
    phoneNumber?: string;
  };
  delivery: {
    address: {
      addressLine1: string;
      addressLine2?: string;
      city: string;
      country: string;
      zipCode: string;
    };
    deliveryNotes?: string;
  };
};

import { ApiError, CartApiError } from "@customTypes/api_error";
import { Result } from "@customTypes/result";
import api from "@network/api";
import { Endpoints } from "@network/endpoints";
import { CartResponse } from "./types/cart_data_response";
import { AxiosError } from "axios";

type addToCartProps = {
  id: string;
  quantity: number;
};

type IDProps = {
  id: string;
};

class CartDataSource {
  static getCartData = async (): Promise<
    Result<CartResponse, CartApiError>
  > => {
    try {
      const response = await api.get(Endpoints.getCart);
      if (response.status === 200) {
        return {
          ok: true,
          value: response.data,
        };
      } else if (response.status === 404) {
        return {
          ok: false,
          error: CartApiError.noBasket,
        };
      }
      return {
        ok: false,
        error: CartApiError.unknown,
      };
    } catch (error) {
      const axiosError = error as AxiosError;
      if (axiosError.response?.status === 404) {
        return {
          ok: false,
          error: CartApiError.noBasket,
        };
      }
      return {
        ok: false,
        error: CartApiError.unknown,
      };
    }
  };

  static addItemToCart = async ({
    id,
    quantity,
  }: addToCartProps): Promise<Result<CartResponse, ApiError>> => {
    try {
      const response = await api.patch(Endpoints.cartItem, {
        item_uid: id,
        quantity,
      });
      if (response.status === 200) {
        return {
          ok: true,
          value: response.data,
        };
      }
      return {
        ok: false,
        error: ApiError.unknown,
      };
    } catch {
      return {
        ok: false,
        error: ApiError.unknown,
      };
    }
  };

  static removeItemFromCart = async ({
    id,
  }: IDProps): Promise<Result<CartResponse, ApiError>> => {
    try {
      const response = await api.delete(`${Endpoints.cartItem}/${id}`);
      if (response.status === 200) {
        return {
          ok: true,
          value: response.data,
        };
      }
      return {
        ok: false,
        error: ApiError.unknown,
      };
    } catch {
      return {
        ok: false,
        error: ApiError.unknown,
      };
    }
  };

  static updateCartItem = async ({
    id,
    quantity,
  }: addToCartProps): Promise<Result<CartResponse, ApiError>> => {
    try {
      const response = await api.patch(Endpoints.cartItem, {
        item_uid: id,
        quantity,
      });
      if (response.status === 200) {
        return {
          ok: true,
          value: response.data,
        };
      }
      return {
        ok: false,
        error: ApiError.unknown,
      };
    } catch {
      return {
        ok: false,
        error: ApiError.unknown,
      };
    }
  };
}

export { CartDataSource };

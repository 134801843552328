import FirstLoadOverlay from "@components/first_load_overlay";
import { useFirstLoadData } from "@hooks/use_first_load_data";
import { useLayoutData } from "@hooks/use_layout_data";
import { useNetworkCheck } from "@hooks/use_network_check";
import { BREAKPOINTS } from "@styles/breakpoints";
import { colours } from "@styles/theme";
import Head from "next/head";
import { useRouter } from "next/router";
import React, { useEffect, useRef } from "react";
import styled from "styled-components";
import { Footer } from "../footer";
import Header from "../header";
import { SideMenu } from "../side_menu";
import { SCROLL_THUMB_WIDTH, isStandbyMode } from "@utils/constants";
import { Routes } from "@router/routes";
import PageLoader from "@components/page_loader";

const LayoutWrapper = styled.div`
  position: relative;
  display: block;
  background-color: ${colours.black};

  overflow-y: hidden;
  height: 100vh;
  max-height: -webkit-fill-available;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    height: 100vh;
    overflow-x: hidden;
  }
`;

interface MenuInterface {
  isOpen: boolean;
  menuWidth: number;
}

const MenuContainer = styled.div<MenuInterface>`
  height: 100%;
  background-color: ${colours.white};
  opacity: ${(props) => (props.isOpen ? 1 : 0.9)};
  width: ${(props) => props.menuWidth}px;
  display: ${(props) => (props.isOpen ? "block" : "block")};
  right: auto;
  left: 0px;
  transition: all 0.4s cubic-bezier(0.1, 0.7, 0.1, 1) 0s;
  transform: ${(props) =>
    props.isOpen ? "translate3d(0%, 0px, 0px)" : "translate3d(-10%, 0px, 0px)"};
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;

  display: none;
  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    display: block;

    @media (orientation: landscape) {
      overflow-y: auto;
    }
  }
`;

const SlidingWrapper = styled.div<MenuInterface>`
  user-select: none;
  touch-action: pan-y;
  -webkit-user-drag: none;
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
  box-shadow: rgb(0 0 0 / 20%) 0px 0px 10px 0px;
  transition: ${(props) =>
    props.isOpen
      ? "all 0.4s cubic-bezier(0.1, 0.7, 0.1, 1) 0s"
      : "all 0.4s cubic-bezier(0.1, 0.7, 0.1, 1) 0s"};
  transform: ${(props) => `translate3d(${props.menuWidth}px, 0px, 0px)`};
  height: 100vh;
  max-height: -webkit-fill-available;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    height: 100vh;
  }
`;

const FirstLoadOverlayWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 80;
`;

const ContentContainer = styled.div`
  height: 100vh;
  max-height: -webkit-fill-available;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    height: 100vh;
  }
`;

interface MainInterface {
  height: number;
  menuIsOpen: boolean;
}
const Main = styled.main<MainInterface>`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  overflow-y: scroll;
  background-color: ${colours.white};
  height: ${(props) => `calc(100% - ${props.height}px)`};
  pointer-events: ${(props) => (props.menuIsOpen ? "none" : "auto")};
  transition: 0s ease-in;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    height: ${(props) => `calc(100% - ${props.height}px)`};
  }

  ::-webkit-scrollbar {
    width: ${SCROLL_THUMB_WIDTH}px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: ${colours.white};
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: ${colours.offWhite};
    border-radius: 5px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

const LoadingContainer = styled.div`
  margin-top: 100px;
`;

type Props = {
  children: React.ReactNode;
};

const Layout = ({ children }: Props) => {
  useNetworkCheck();
  const mainRef = useRef<HTMLDivElement>();
  const router = useRouter();
  const {
    menuIsOpen,
    menuWidth,
    desktopHeaderHeight,
    hideHeaderShadow,
    headerContainerHeight,
    closeMenu,
    updateHeaderHeight,
  } = useLayoutData();

  const { onGuestContinue, onWalletContinue, showFirstLoadOverlay } =
    useFirstLoadData();

  useEffect(() => {
    //Used to scroll to top of page on route change
    mainRef.current?.scrollTo(0, 0);
  }, [router.asPath]);

  const onContentClick = () => {
    if (menuIsOpen) {
      closeMenu();
    }
  };

  const onScroll = (e: React.UIEvent<HTMLElement, UIEvent>) => {
    const target = e.target as HTMLTextAreaElement;
    const scrollYPosition = target.scrollTop;
    updateHeaderHeight(scrollYPosition);
  };

  const onSlidingWrapperClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    menuIsOpen ? onContentClick() : e.stopPropagation();
  };

  useEffect(() => {
    if (isStandbyMode && router.asPath !== Routes.home) {
      router.replace(Routes.home);
    }
  }, []);

  const renderBody = () => {
    if (isStandbyMode && router.asPath !== Routes.home)
      return (
        <LoadingContainer>
          <PageLoader />
        </LoadingContainer>
      );
    return children;
  };

  return (
    <LayoutWrapper>
      {showFirstLoadOverlay && (
        <FirstLoadOverlayWrapper>
          <FirstLoadOverlay
            onGuestContinue={onGuestContinue}
            onWalletContinue={onWalletContinue}
          />
        </FirstLoadOverlayWrapper>
      )}
      {!isStandbyMode && (
        <MenuContainer isOpen={menuIsOpen} menuWidth={menuWidth}>
          <SideMenu />
        </MenuContainer>
      )}
      <SlidingWrapper
        isOpen={menuIsOpen}
        menuWidth={menuWidth}
        onClick={onSlidingWrapperClick}
      >
        <Head>
          <title>Hape Apparel</title>
          <meta name="description" content="Generated by create next app" />
          <meta name="viewport" content="width=device-width, initial-scale=1" />
          <link rel="icon" href="/favicon.ico" />
        </Head>
        <ContentContainer>
          <Header
            desktopHeaderHeight={desktopHeaderHeight}
            hideShadow={hideHeaderShadow}
          />
          <Main
            // @ts-ignore
            ref={mainRef}
            menuIsOpen={menuIsOpen}
            onScroll={onScroll}
            height={headerContainerHeight}
          >
            {renderBody()}
            <Footer />
          </Main>
        </ContentContainer>
      </SlidingWrapper>
    </LayoutWrapper>
  );
};

export { Layout };

import { BREAKPOINTS } from "@styles/breakpoints";
import { useState, useEffect } from "react";

const getWindowDimensions = () => {
  if (typeof window !== "undefined") {
    const { innerWidth: width, innerHeight: height } = window;
    return {
      width,
      height,
    };
  }
  return { width: 0, height: 0 };
};

const useWindowDimensions = () => {
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  const handleResize = () => {
    const dimensions = getWindowDimensions();
    setWindowDimensions(dimensions);
  };

  useEffect(() => {
    handleResize();
    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowDimensions;
};

export const useIsMobileWidth = () => {
  const { width } = useWindowDimensions();
  return { isMobileWidth: width < BREAKPOINTS.mobile, screenWidth: width };
};

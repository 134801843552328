export const Routes = {
  home: "/",
  products: "/products",
  lookBook: "/look_book",
  cart: "/cart",
  productDetails: (itemId: string, colour: string) =>
    `/product_details?item_id=${itemId}&colour=${colour}`,
  delivery: "/delivery",
  orderDetails: (orderId: string) => `/order_details?order_id=${orderId}`,
  orderCompleted: "/order_completed",
};

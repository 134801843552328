import { colours } from "@styles/theme";
import styled from "styled-components";
import ClipLoader from "react-spinners/ClipLoader";

const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const PageLoader = () => {
  return (
    <Container>
      <ClipLoader color={colours.black} />
    </Container>
  );
};

export default PageLoader;

export const Endpoints = {
  authorizeWalletUser: "/users/wallet-login",
  authorizeGuestUser: "/users/guest-login",
  getproducts: "/merchandise/products",
  getproductsDetails: (id: string) => `/merchandise/products/${id}/items`,
  getFAQs: "/content/faqs",
  getLandingPageData: "/content/landing-page-copy",
  getCart: "/checkout/baskets/",
  cartItem: "/checkout/baskets/items",
  createOrder: "/checkout/orders/",
  getUserOrder: "/checkout/orders/",
  updateOrder: (orderId: string) => `/checkout/orders/${orderId}`,
  getOrder: (id: string) => `/checkout/orders/${id}`,
  createPayment: (orderId: string) => `/payments/orders/${orderId}`,
  getPaymentStatus: (paymentId: string) =>
    `/payments/payments/${paymentId}/status`,
  getTokens: (orderId: string) => `/checkout/orders/${orderId}/discount_tokens`,
};

export const publicEndpoints = [
  Endpoints.authorizeWalletUser,
  Endpoints.authorizeGuestUser,
  Endpoints.getFAQs,
  Endpoints.getLandingPageData,
];

import { BREAKPOINTS } from "@styles/breakpoints";
import Image from "next/image";
import { colours } from "@styles/theme";
import styled from "styled-components";
import { LinkA, LinkText, Row } from "../base_styles";

import TwitterIcon from "../../public/twitter_icon.svg";
import InstagramIcon from "../../public/instagram_icon.svg";
import DiscordIcon from "../../public/discord_icon.svg";

import {
  DISCORD_URL,
  INSTAGRAM_URL,
  PRIVACY_POLICY_URL,
  TERMS_AND_CONDITIONS_URL,
  TWITTER_URL,
} from "@utils/constants";

const Wrapper = styled(Row)`
  justify-content: space-between;
  padding: 42px 32px;
  width: 100%;
  padding-top: 70px;
  background-color: ${colours.white};

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    flex-direction: column;
    justify-content: center;
    padding-top: 50px;
  }
`;

const TextWrapper = styled(Row)`
  align-items: center;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    flex-direction: column;
  }
`;

interface TermsTextInterface {
  bottomMargin?: boolean;
}
const TermsText = styled(LinkText)<TermsTextInterface>`
  margin-bottom: 0px;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    margin-bottom: 8px;

    ${(props) => props.bottomMargin && `margin-bottom: 24px;`};
  }
`;

const HapeText = styled.p`
  color: ${colours.black};
  font-size: 12px;
  font-weight: 300;
  margin-bottom: 0px;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    margin-bottom: 8px;
  }
`;

const Divider = styled.div`
  display: flex;
  background-color: ${colours.black};
  margin: 2px 10px;
  width: 1px;
  height: 10px;

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    display: none;
  }
`;

const SocialsWrapper = styled(Row)``;

interface SocialLinkInterface {
  padded?: boolean;
}
const SocialLink = styled(LinkA)<SocialLinkInterface>`
  margin: ${(props) => (props.padded ? " 0 20px" : 0)};
  margin: 0 10px;
`;

const Footer = () => {
  return (
    <Wrapper>
      <TextWrapper>
        <HapeText>© 2022 Hape®. All Rights Reserved.</HapeText>
        <Divider />
        <TermsText
          href={TERMS_AND_CONDITIONS_URL}
          target="_blank"
          color={colours.black}
        >
          Terms and conditions
        </TermsText>
        <Divider />
        <TermsText
          href={PRIVACY_POLICY_URL}
          target="_blank"
          color={colours.black}
          bottomMargin
        >
          Privacy Policy
        </TermsText>
      </TextWrapper>
      <SocialsWrapper>
        <SocialLink href={TWITTER_URL} target="_blank">
          <Image src={TwitterIcon.src} height={18} width={22} alt="" />
        </SocialLink>
        <SocialLink href={INSTAGRAM_URL} target="_blank">
          <Image src={InstagramIcon.src} height={18} width={22} alt="" />
        </SocialLink>
        <SocialLink href={DISCORD_URL} target="_blank">
          <Image src={DiscordIcon.src} height={18} width={22} alt="" />
        </SocialLink>
      </SocialsWrapper>
    </Wrapper>
  );
};
export { Footer };

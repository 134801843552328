import { ApiError } from "@customTypes/api_error";
import { Result } from "@customTypes/result";
import api from "@network/api";
import { Endpoints } from "@network/endpoints";
import {
  ItemDataResponse,
  ProductDataResponse,
} from "./types/product_data_response";

class ProductDataSource {
  static getProductListing = async (): Promise<
    Result<ProductDataResponse, ApiError>
  > => {
    try {
      const response = await api.get(Endpoints.getproducts);
      if (response.status === 200) {
        return {
          ok: true,
          value: response.data,
        };
      }
      return {
        ok: false,
        error: ApiError.unknown,
      };
    } catch {
      return {
        ok: false,
        error: ApiError.unknown,
      };
    }
  };

  static getProductDetails = async (
    id: string
  ): Promise<Result<ItemDataResponse, ApiError>> => {
    try {
      const response = await api.get(Endpoints.getproductsDetails(id));

      if (response.status === 200) {
        return {
          ok: true,
          value: response.data,
        };
      }
      return {
        ok: false,
        error: ApiError.unknown,
      };
    } catch (error) {
      return {
        ok: false,
        error: ApiError.unknown,
      };
    }
  };
}
export { ProductDataSource };

import { CartContext } from "@providers/cart_provider";
import { Routes } from "@router/routes";
import { BREAKPOINTS } from "@styles/breakpoints";
import { colours } from "@styles/theme";
import { useRouter } from "next/router";
import { useContext, useEffect, useState } from "react";
import styled from "styled-components";
import CartLogo from "../../../public/cart_icon.svg";
import IconButton from "../../icon_button";
import { HeaderClickableItem, HeaderTextItem } from "../header_items";

const SHAKING_ANIMATION_DURATION_SECONDS = 0.6;
const SHAKING_ANIMATION_DURATION_MS = SHAKING_ANIMATION_DURATION_SECONDS * 1000;

const Wrapper = styled.div`
  position: relative;
`;

const CartText = styled(HeaderTextItem)`
  margin-right: 2px;
`;

const Shaking = styled.div<CirclePulseInterface>`
  animation: ${(props) =>
    props.isPulsing ? "jump-shaking 0.6s infinite" : "none"};

  @media (max-width: ${BREAKPOINTS.mobilePx}) {
    animation: ${(props) =>
      props.isPulsing
        ? `shaking ${SHAKING_ANIMATION_DURATION_SECONDS}s infinite`
        : "none"};
  }
`;

const Circle = styled(Shaking)`
  position: absolute;
  top: -5px;
  right: -5px;
  display: flex;
  min-height: 20px;
  min-width: 20px;
  border-radius: 10px;
  align-items: center;
  justify-content: center;
  background-color: ${colours.black};
  padding: 0 6px;
`;

const CirclePulseWrapper = styled(Shaking)`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: -10px;
  right: -10px;
  min-height: 30px;
  min-width: 30px;
  border-radius: 15px;
  background-color: transparent;
`;

interface CirclePulseInterface {
  isPulsing: boolean;
}

const CirclePulse = styled.div<CirclePulseInterface>`
  min-height: ${(props) => (props.isPulsing ? "30px" : "20px")};
  min-width: ${(props) => (props.isPulsing ? "30px" : "20px")};
  border-radius: ${(props) => (props.isPulsing ? "15px" : "10px")};
  background-color: ${colours.black}40;
  transition: ${SHAKING_ANIMATION_DURATION_SECONDS}s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
`;

const CitcleText = styled.p`
  color: ${colours.white};
  font-size: 10px;
`;

const Cart = () => {
  const { cartItemCount, hasUpdatedCart } = useContext(CartContext);
  const router = useRouter();
  const [isPulsing, setIsPulsing] = useState(false);
  const isCartPage = router.pathname === Routes.cart.replace(".html", "");

  useEffect(() => {
    if (hasUpdatedCart) {
      setIsPulsing(true);
      setTimeout(() => {
        setIsPulsing(false);
      }, SHAKING_ANIMATION_DURATION_MS);
    }
  }, [hasUpdatedCart]);

  return (
    <Wrapper>
      <HeaderClickableItem isSelected={isCartPage}>
        <Shaking isPulsing={isPulsing}>
          <IconButton src={CartLogo.src} height={20} width={18} />
        </Shaking>
      </HeaderClickableItem>
      {cartItemCount !== 0 && (
        <>
          <CirclePulseWrapper isPulsing={isPulsing}>
            <CirclePulse isPulsing={isPulsing}>
              {<CitcleText>{cartItemCount}</CitcleText>}
            </CirclePulse>
          </CirclePulseWrapper>
          <Circle isPulsing={isPulsing}>
            <CitcleText>{cartItemCount}</CitcleText>
          </Circle>
        </>
      )}
    </Wrapper>
  );
};
export { Cart };

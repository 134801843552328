import { Item, ItemData } from "@customTypes/item";
import { Product, ProductData } from "@customTypes/product";
import { Result } from "@customTypes/result";
import { ProductRepository } from "../repository/product_repository";

class ProductUseCase {
  static getProductListing = async (): Promise<Result<ProductData, any>> => {
    return ProductRepository.getProductListing();
  };

  static getProductDetails = async (
    id: string
  ): Promise<Result<ItemData, any>> => {
    return ProductRepository.getProductDetails(id);
  };
}

export { ProductUseCase };

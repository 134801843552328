import Image from "next/image";
import styled from "styled-components";
import { ClickableRow } from "../base_styles";
import WalletConnectIconBlack from "../../public/wallet_connect_icon_black.svg";
import WalletConnectIconWhite from "../../public/wallet_connect_icon_white.svg";
import { colours } from "@styles/theme";
import { useContext } from "react";
import { shortenedWalletAddress } from "@utils/utils";
import { WalletContext } from "@providers/wallet_provider";

interface ReverseInterface {
  reverse: boolean;
  light?: boolean;
}
const Wrapper = styled(ClickableRow)<ReverseInterface>`
  padding: 0 4px;
  flex-direction: ${(props) => (props.reverse ? "row-reverse" : "row")};
  flex-wrap: nowrap;
`;

const ItemText = styled.h3<ReverseInterface>`
  color: ${(props) => (props.light ? colours.white : colours.black)};
  font-size: 12px;
  cursor: pointer;
  margin-right: 6px;
  ${(props) => props.reverse && "margin-left: 10px"};
  white-space: nowrap;
`;

type Props = {
  reverse?: boolean;
  light?: boolean;
};

const WalletConnect = ({ reverse = false, light = false }: Props) => {
  const { walletAddress, isConnected, openWalletConnectModal } =
    useContext(WalletContext);

  const onOpen = async () => {
    await openWalletConnectModal();
  };

  return (
    <Wrapper onClick={onOpen} reverse={reverse}>
      <ItemText reverse={reverse} light={light}>
        {isConnected ? shortenedWalletAddress(walletAddress) : "Not connected"}
      </ItemText>
      <Image
        priority
        src={light ? WalletConnectIconWhite : WalletConnectIconBlack.src}
        height={22}
        width={32}
        alt=""
      />
    </Wrapper>
  );
};

export { WalletConnect };

import { colours } from "@styles/theme";
import styled from "styled-components";

interface ButtonInterface {
  disabled?: boolean;
  outline?: boolean;
}
export const Button = styled.button<ButtonInterface>`
  background-color: ${(props) =>
    props.outline ? `${colours.white}` : `${colours.black}`};
  color: ${(props) =>
    props.outline ? `${colours.black}` : `${colours.white}`};
  border: 1px solid ${colours.black};
  border-radius: 6px;
  padding: 12px;
  width: 100%;
  justify-content: center;
  font-size: 12px;
  font-weight: 300;
  opacity: ${(props) => (props.disabled ? "0.5" : "1")};
`;

import { ClickableRow } from "@components/base_styles";
import styled from "styled-components";
import Image from "next/image";
import { colours } from "@styles/theme";

const Wrapper = styled(ClickableRow)`
  align-items: center;
  padding-top: 24px;
  padding-bottom: 25px;
  border-bottom: 1px solid ${colours.offBlack};
`;

const RowText = styled.h2`
  margin: 0;
  padding: 0;
  font-size: 12px;
  color: ${colours.white};
  margin-right: auto;
  white-space: nowrap;
`;

type Props = {
  text: String;
  onClick?: () => void;
};

const IconRow = ({ text, onClick }: Props) => {
  return (
    <Wrapper onClick={onClick}>
      <RowText>{text}</RowText>
      <Image src="/right_chevron.svg" height={12} width={8} alt="" />
    </Wrapper>
  );
};

export { IconRow };

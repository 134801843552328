export const colours: { [kei: string]: string } = {
  white: "#FFFFFF",
  black: "#000000",
  darkText: "#757575",
  dark: "#131313",
  green: "#0BBB07",
  offWhite: "#bcbcbc",
  lightGrey: "#DDDDDD",
  red: "#E4002B",
  orange: "#FF6A00",
  offBlack: "#313131",
};

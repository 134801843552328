import {
  createContext,
  useState,
  SetStateAction,
  Dispatch,
  useEffect,
} from "react";
import { HEADER_HEIGHT_DESKTOP } from "@utils/constants";
import { useIsMobileWidth } from "@utils/hooks/use_is_mobile_width";

export const MAX_MENU_WIDTH = 500;

export type MenuContextValues = {
  openMenu: () => void;
  closeMenu: () => void;
  menuIsOpen: boolean;
  menuWidth: number;
  desktopHeaderHeight: number;
  setDesktopHeaderHeight: Dispatch<SetStateAction<number>>;
};

export const MenuContext = createContext<MenuContextValues>({
  openMenu: () => {},
  closeMenu: () => {},
  menuIsOpen: false,
  menuWidth: 0,
  desktopHeaderHeight: 0,
  setDesktopHeaderHeight: () => {},
});

type Props = {
  children: React.ReactNode;
};

export const MenuProvider = ({ children }: Props) => {
  const [menuWidth, setMenuWidth] = useState(0);
  const [desktopHeaderHeight, setDesktopHeaderHeight] = useState(
    HEADER_HEIGHT_DESKTOP
  );
  const { screenWidth } = useIsMobileWidth();

  useEffect(() => {
    if (menuWidth > 0) {
      setMenuWidth(screenWidth);
    }
  }, [screenWidth]);

  const openMenu = () => setMenuWidthWithMax();

  const closeMenu = () => setMenuWidth(0);

  const menuIsOpen =
    screenWidth > MAX_MENU_WIDTH
      ? menuWidth === MAX_MENU_WIDTH
      : menuWidth === screenWidth;

  const setMenuWidthWithMax = () => {
    const width = screenWidth > MAX_MENU_WIDTH ? MAX_MENU_WIDTH : screenWidth;
    setMenuWidth(width);
  };

  return (
    <MenuContext.Provider
      value={{
        openMenu,
        closeMenu,
        menuIsOpen,
        menuWidth,
        desktopHeaderHeight,
        setDesktopHeaderHeight,
      }}
    >
      {children}
    </MenuContext.Provider>
  );
};

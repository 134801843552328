import { ApiError } from "@customTypes/api_error";
import {
  CreateOrderInput,
  Order,
  OrderTokens,
  UpdateOrderInput,
} from "@customTypes/order";
import { Result } from "@customTypes/result";
import { OrderRepository } from "@repository/order_repository";

class OrderUseCase {
  static getOrder = async (id: string): Promise<Result<Order, ApiError>> => {
    return OrderRepository.getOrder(id);
  };

  static createOrder = async (
    orderInput: CreateOrderInput
  ): Promise<Result<Order, ApiError>> => {
    return OrderRepository.createOrder(orderInput);
  };

  static updateOrder = async (
    orderInput: UpdateOrderInput
  ): Promise<Result<Order, ApiError>> => {
    return OrderRepository.updateOrder(orderInput);
  };

  static getActiveOrder = async (): Promise<Result<Order, ApiError>> => {
    return OrderRepository.getActiveOrder();
  };

  static getTokensInOrder = async (
    orderId: string
  ): Promise<Result<OrderTokens, ApiError>> => {
    return OrderRepository.getTokensInOrder(orderId);
  };
}

export { OrderUseCase };

import { colours } from "@styles/theme";

export enum Colour {
  white = "white",
  orange = "orange",
  black = "black",
}

export const colourKeyMap = {
  [Colour.orange]: colours.orange,
  [Colour.black]: colours.black,
  [Colour.white]: colours.white,
};

export const mapColourToType = (colour: string) => {
  if (colour === "orange") return Colour.orange;
  if (colour === "white") return Colour.white;
  if (colour === "black") return Colour.black;
  return Colour.white;
};

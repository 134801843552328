import { createContext, useContext, useEffect, useState } from "react";
import { WalletContext } from "./wallet_provider";
import { v4 as uuidv4 } from "uuid";
import { useSignMessage } from "wagmi";
import { useAuthentication } from "@hooks/use_authentication";
import { ErrorContext } from "./error_provider";
import { UserType } from "@customTypes/user_type";

type SignatureContextTypes = {
  userType: UserType;
};
export const SignatureContext = createContext<SignatureContextTypes>({
  userType: UserType.unknown,
});

const loginSigningMessage = [
  "Hape is requesting your signature to allow you recieve discounts on eligible items.",
  "We need to do this to prove you are the owner of this wallet.",
  "Please sign me in!",
  "Don't worry this will not cost you any ETH",
  `Random code for security: ${uuidv4()}`,
].join("\n\n");

type Props = {
  children: React.ReactNode;
};

const SignatureProvider = ({ children }: Props) => {
  const { isConnected, isValidChain, walletAddress, connector } =
    useContext(WalletContext);
  const { setMenuErrorMessage } = useContext(ErrorContext);
  const { walletLogin, logoutWalletUser, userType } = useAuthentication();

  const { isLoading, signMessage: signLoginMessage } = useSignMessage({
    message: loginSigningMessage,
    onError: () => {
      onSigningError();
    },
    onSuccess: (data) => {
      walletLogin({
        walletAddress,
        signature: data,
        message: loginSigningMessage,
      });
    },
  });

  const onSigningError = () => {
    logoutWalletUser();

    // TODO: Fix this race condition
    setTimeout(() => {
      setMenuErrorMessage({
        value: "Error connecting wallet",
        dismissable: true,
      });
    }, 500);
  };

  useEffect(() => {
    if (
      isConnected &&
      isValidChain &&
      !isLoading &&
      !!connector &&
      userType === UserType.guest
    ) {
      setTimeout(() => {
        signLoginMessage();
      }, 500);
    }
  }, [isConnected, isValidChain, userType]);

  return (
    <SignatureContext.Provider value={{ userType }}>
      {children}
    </SignatureContext.Provider>
  );
};

export { SignatureProvider };

export const HEADER_HEIGHT_DESKTOP = 84;
export const HEADER_HEIGHT_DESKTOP_COMPRESSSED = 58;
export const HEADER_HEIGHT_MOBILE = 56;

export const HEADER_HEIGHT_DESKTOP_PX = "84px";
export const HEADER_HEIGHT_MOBILE_PX = "56px";

export const ERROR_MESSAGE_HEIGHT_DESKTOP = 42;
export const ERROR_MESSAGE_HEIGHT_MOBILE = 55;

export const MAX_CONTENT_WIDTH = "1400px";
export const MAX_CHECKOUT_CONTENT_WIDTH = "1100px";

export const SCROLL_THUMB_WIDTH = 5;

export const TWITTER_URL = "https://twitter.com/HAPEsocial";
export const INSTAGRAM_URL = "https://www.instagram.com/hapesocial/";
export const DISCORD_URL = "https://discord.com/invite/hape";

export const TERMS_AND_CONDITIONS_URL =
  "https://acrobat.adobe.com/link/track?uri=urn:aaid:scds:US:e7ca2f74-bdab-41cc-8d70-21b278fbfc33";

export const PRIVACY_POLICY_URL =
  "https://acrobat.adobe.com/link/track?uri=urn:aaid:scds:US:53e22664-5590-42dd-afc4-fd0d388025ac";

export const isDebug = process.env.NEXT_PUBLIC_ENVIRONMENT === "debug";
export const isProduction =
  process.env.NEXT_PUBLIC_ENVIRONMENT === "production";

export const isStandbyMode = process.env.NEXT_PUBLIC_STANDBY_MODE === "true";

export const HAPE_PRIME_COLLETION_URL =
  "https://opensea.io/collection/hapeprime";

export const SUPPORTED_COUNTRIES = [
  "Algeria",
  "Andorra",
  "Angola",
  "Antigua and Barbuda",
  "Argentina",
  "Armenia",
  "Australia",
  "Austria",
  "Azerbaijan",
  "Bahamas",
  "Bahrain",
  "Bangladesh",
  "Barbados",
  "Belgium",
  "Belize",
  "Benin",
  "Bhutan",
  "Bolivia",
  "Bosnia and Herzegovina",
  "Botswana",
  "Brazil",
  "Brunei",
  "Bulgaria",
  "Burkina Faso",
  "Burundi",
  "Cabo Verde",
  "Cambodia",
  "Cameroon",
  "Canada",
  "Chad",
  "Chile",
  "Colombia",
  "Comoros",
  "Congo (Congo-Brazzaville)",
  "Costa Rica",
  "Croatia",
  "Cuba",
  "Cyprus",
  "Czech Republic",
  "Côte d'Ivoire",
  "Democratic Republic of the Congo",
  "Denmark",
  "Djibouti",
  "Dominica",
  "Dominican Republic",
  "Ecuador",
  "Egypt",
  "El Salvador",
  "Equatorial Guinea",
  "Eritrea",
  "Estonia",
  "Ethiopia",
  "Fiji",
  "Finland",
  "France",
  "Gabon",
  "Gambia",
  "Georgia",
  "Germany",
  "Ghana",
  "Greece",
  "Grenada",
  "Guatemala",
  "Guinea",
  "Guyana",
  "Haiti",
  "Holy See",
  "Honduras",
  "Hungary",
  "Iceland",
  "India",
  "Indonesia",
  "Iraq",
  "Ireland",
  "Israel",
  "Italy",
  "Jamaica",
  "Japan",
  "Kazakhstan",
  "Kenya",
  "Kiribati",
  "Kuwait",
  "Kyrgyzstan",
  "Laos",
  "Latvia",
  "Lebanon",
  "Lesotho",
  "Liberia",
  "Libya",
  "Liechtenstein",
  "Lithuania",
  "Luxembourg",
  "Madagascar",
  "Malawi",
  "Malaysia",
  "Maldives",
  "Mali",
  "Malta",
  "Marshall Islands",
  "Mauritania",
  "Mauritius",
  "Mexico",
  "Micronesia",
  "Moldova",
  "Monaco",
  "Mongolia",
  "Montenegro",
  "Morocco",
  "Mozambique",
  "Myanmar (formerly Burma)",
  "Namibia",
  "Nauru",
  "Nepal",
  "Netherlands",
  "New Zealand",
  "Nicaragua",
  "Niger",
  "Nigeria",
  "North Macedonia",
  "Norway",
  "Oman",
  "Pakistan",
  "Panama",
  "Papua New Guinea",
  "Paraguay",
  "Peru",
  "Philippines",
  "Poland",
  "Portugal",
  "Qatar",
  "Romania",
  "Rwanda",
  "Saint Kitts and Nevis",
  "Saint Lucia",
  "Saint Vincent and the Grenadines",
  "Samoa",
  "San Marino",
  "Sao Tome and Principe",
  "Saudi Arabia",
  "Senegal",
  "Serbia",
  "Seychelles",
  "Sierra Leone",
  "Singapore",
  "Slovakia",
  "Slovenia",
  "Somalia",
  "South Africa",
  "South Korea",
  "South Sudan",
  "Spain",
  "Sri Lanka",
  "Sudan",
  "Suriname",
  "Sweden",
  "Switzerland",
  "Tajikistan",
  "Tanzania",
  "Thailand",
  "Timor-Leste",
  "Togo",
  "Tonga",
  "Trinidad and Tobago",
  "Tunisia",
  "Turkey",
  "Turkmenistan",
  "Tuvalu",
  "Uganda",
  "Ukraine",
  "United Arab Emirates",
  "United Kingdom",
  "United States of America",
  "Uruguay",
  "Uzbekistan",
  "Vanuatu",
  "Vietnam",
  "Yemen",
  "Zambia",
  "Zimbabwe",
];

import styled from "styled-components";
import Image from "next/image";
import { MouseEventHandler } from "react";

const Wrapper = styled.button``;

type Props = {
  height: number;
  width: number;
  src: any;
  onClick?: MouseEventHandler<HTMLButtonElement> | undefined;
};

const IconButton = ({ height, width, src, onClick }: Props) => {
  return (
    <Wrapper onClick={onClick}>
      <Image src={src} height={height} width={width} alt="" />
    </Wrapper>
  );
};

export default IconButton;

import { Currency, getCurrencySymbol } from "@customTypes/currency";
import { ethers } from "ethers";

export const shortenedWalletAddress = (text: string) => {
  const limit = 8;
  if (text.length > limit) {
    return (
      text.substr(0, limit) + "..." + text.substr(text.length - 8, text.length)
    );
  }
  return text;
};

const usdCurrencyFormatter = new Intl.NumberFormat("en-US", {
  style: "currency",
  currency: "USD",
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

const gbpCurrencyFormatter = new Intl.NumberFormat("en-GB", {
  style: "currency",
  currency: "GBP",
  // These options are needed to round to whole numbers if that's what you want.
  //minimumFractionDigits: 0, // (this suffices for whole numbers, but will print 2500.10 as $2,500.1)
  //maximumFractionDigits: 0, // (causes 2500.99 to be printed as $2,501)
});

export const formatCurrency = (price: number, currency: Currency): string => {
  switch (currency) {
    case Currency.eth:
      return `${price}`;
    case Currency.usd:
      return usdCurrencyFormatter.format(price / 100);
    case Currency.gbp:
      return gbpCurrencyFormatter.format(price / 100);
  }
};

export const getFormattedPrice = (
  price: number,
  activeCurrency: Currency
): string => {
  const curencySymbol = getCurrencySymbol(activeCurrency);
  if (activeCurrency === Currency.eth) {
    try {
      const eth = ethers.utils.formatEther(price.toString());
      const number = parseFloat(eth).toFixed(5);
      return `${number} ${curencySymbol}`;
    } catch {
      return `${price} ${curencySymbol}`;
    }
  }
  return formatCurrency(price, activeCurrency);
};

type GetPriceProps = {
  ethValue: number;
  gbpValue: number;
  usdValue: number;
  currency: Currency;
};
export const getPrice = ({
  ethValue,
  gbpValue,
  usdValue,
  currency,
}: GetPriceProps) => {
  switch (currency) {
    case Currency.eth:
      return ethValue;
    case Currency.usd:
      return usdValue;
    case Currency.gbp:
      return gbpValue;
  }
};

export const getFormattedActivePrice = ({
  ethValue,
  gbpValue,
  usdValue,
  currency,
}: GetPriceProps) => {
  switch (currency) {
    case Currency.eth:
      return getFormattedPrice(ethValue, currency);
    case Currency.usd:
      return getFormattedPrice(usdValue, currency);
    case Currency.gbp:
      return getFormattedPrice(gbpValue, currency);
  }
};

export const buildImageUrl = (url: string) => {
  return url;
};

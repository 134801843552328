import { ErrorContext } from "@providers/error_provider";
import { WalletContext } from "@providers/wallet_provider";
import { useContext, useEffect } from "react";
import { authorisedNetwork } from "../../chain_network/chain_network";

const useNetworkCheck = () => {
  const { setMenuErrorMessage, clearMenuErrorMessage } =
    useContext(ErrorContext);
  const { isConnected, isValidChain } = useContext(WalletContext);

  useEffect(() => {
    if (!isValidChain && !!isConnected) {
      setMenuErrorMessage({
        value: `You are connected to the incorrect network. Please connect to the ${authorisedNetwork} network`,
      });
    } else {
      clearMenuErrorMessage();
    }
  }, [isConnected, isValidChain]);
};
export { useNetworkCheck };

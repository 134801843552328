import styled from "styled-components";

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Clickable = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;

  :active {
    opacity: 0.5;
  }
`;

export const ClickableRow = styled(Clickable)`
  flex-direction: row;
`;

export const ClickableColumn = styled(Clickable)`
  flex-direction: column;
`;

interface LinkInterface {
  color: string;
}
export const LinkText = styled.a<LinkInterface>`
  cursor: pointer;
  font-size: 12px;
  color: ${(props) => `${props.color}`};
  opacity: 1;
  font-weight: 300;
  width: fit-content;
  padding-top: 2px;
  border-bottom: 1px solid transparent;
  :hover {
    border-bottom: ${(props) => `1px solid ${props.color}`};
  }

  :active {
    opacity: 0.7;
  }
`;

export const LinkA = styled.a`
  cursor: pointer;
  opacity: 1;

  :active {
    opacity: 0.7;
  }
`;

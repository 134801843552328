import { Currency } from "@customTypes/currency";
import {
  LocalStoreKey,
  getLocalStoreItem,
  setLocalStoreItem,
} from "@storage/local_storage";
import { createContext, useEffect, useState } from "react";

export type CurrencyContextValues = {
  activeCurrency: Currency;
  setActiveCurrency: (price: Currency) => void;
};
export const CurrencyContext = createContext<CurrencyContextValues>({
  activeCurrency: Currency.eth,
  setActiveCurrency: () => {},
});

type Props = {
  children: React.ReactNode;
};

export const CurrencyProvider = ({ children }: Props) => {
  const storedCurrencyIndex =
    getLocalStoreItem(LocalStoreKey.activeCurrency) ?? "0";

  const [activeCurrency, setActiveCurrency] = useState<Currency>(
    parseInt(storedCurrencyIndex)
  );

  useEffect(() => {
    setLocalStoreItem(`${activeCurrency}`, LocalStoreKey.activeCurrency);
  }, [activeCurrency]);

  return (
    <CurrencyContext.Provider value={{ activeCurrency, setActiveCurrency }}>
      {children}
    </CurrencyContext.Provider>
  );
};
